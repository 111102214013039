import {
  css,
  keyframes,
  CSSObject,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import BREAKPOINTS from "./breakpoints";
import type { Breakpoints } from "./breakpoints";
type BreakpointKey = keyof Breakpoints;

const media: Record<
  BreakpointKey,
  (...args: Parameters<typeof css>) => FlattenInterpolation<ThemeProps<any>>
> = {} as Record<
  BreakpointKey,
  (...args: Parameters<typeof css>) => FlattenInterpolation<ThemeProps<any>>
>;

Object.keys(BREAKPOINTS).forEach((key) => {
  const breakpointKey = key as BreakpointKey;
  media[breakpointKey] = (...args) => css`
    @media ${BREAKPOINTS[breakpointKey]} {
      ${css(...args)};
    }
  `;
});

const listReset = () => css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ellipsis = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const menuTransition = () => css`
  &.menuTransition-enter {
    opacity: 0;
  }

  &.menuTransition-enter-active {
    opacity: 1;
    transition: opacity 250ms ease;
  }

  &.menuTransition-exit {
    opacity: 1;
  }

  &.menuTransition-exit-active {
    opacity: 0;
    transition: opacity 150ms ease;
  }
`;

const flickityDotStylesWhite = () => css`
  /* position dots in carousel */
  .flickity-page-dots {
    bottom: -45px;
  }

  /* white circles */
  .flickity-page-dots .dot {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.color.white};
    height: 9px;
    margin: 0 5px;
    opacity: 1;
    transition: background 150ms ease;
    width: 9px;
  }

  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    background: ${({ theme }) => theme.color.white};
  }
`;

const flickityDotStylesBlack = () => css`
  ${flickityDotStylesWhite()}

  /* white circles */
  .flickity-page-dots .dot {
    border: 1px solid ${({ theme }) => theme.color.interface800};
  }

  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    background: ${({ theme }) => theme.color.interface800};
  }
`;

const flickityDotStylesLine = () => css`
  .flickity-page-dots {
    display: flex;
    padding: 0 ${({ theme }) => theme.layout.sidePadding.xsm};

    ${media.small`
      padding: 0 ${({ theme }) => theme.layout.sidePadding.sm};
    `}

    ${media.medium`
      padding: 0 ${({ theme }) => theme.layout.sidePadding.md};
    `}

    ${media.large`
      padding: 0 ${({ theme }) => theme.layout.sidePadding.lg};
    `}

    ${media.xLarge`
      padding: 0 ${({ theme }) => theme.layout.sidePadding.xlg};
    `}
  }

  .flickity-page-dots .dot {
    background-color: ${({ theme }) => theme.color.primary500};
    border-radius: 0;
    display: block;
    flex-shrink: 0;
    flex-grow: 1;
    height: 1px;
    margin: 0;
  }
`;

const flickityPrevNextStyles = () => css`
  .flickity-button {
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    display: none;
    height: 36px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 10px);
    transform: translateY(-50%);
    transition: background-color 150ms ease, opacity 150ms ease;
    width: 36px;

    &.next {
      right: 20px;
    }

    &.previous {
      left: 20px;
    }

    &:hover:not(:disabled) {
      background-color: ${({ theme }) => theme.color.primary600};

      & > svg {
        fill: ${({ theme }) => theme.color.white};
      }
    }

    &:disabled {
      opacity: 0;
    }

    ${media.medium`
      display: flex; 
    `}

    ${media.xLarge`
      width: 42px;
      height: 42px;

      &.next {
        right: 45px;
      }

      &.previous {
        left: 45px;
      }
    `}
  }

  .flickity-button-icon {
    width: 16px;
    height: 16px;
    transition: fill 150ms ease;

    ${media.xLarge`
      width: 20px;
      height: 20px;
    `}
  }
`;

const fadeOutAnim = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export {
  ellipsis,
  fadeOutAnim,
  flickityDotStylesWhite,
  flickityDotStylesBlack,
  flickityDotStylesLine,
  flickityPrevNextStyles,
  listReset,
  media,
  menuTransition,
};

export default {};
