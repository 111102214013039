import { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const globalCSS = () => css`
  *,
  *:before,
  *:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    background-color: #fff;
    color: #000;
    font-family: 'Graphik', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;
    scroll-behavior: smooth;

    // remove google chrome autocomplete color
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  }

  button:focus-visible,
  a:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible {
    outline-width: 1px;
    outline-color: ${({ theme }) => theme.color.interface300};
    outline-style: solid;
  }

  button {
    background: transparent;
    border: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    padding: 0;
  }

  button:focus {
  }

  button:hover {
    cursor: pointer;
  }

  input,
  select {
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
  }

  input[type='range'] {
    width: 100%;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: transparent;
    border: 0 none;
  }

  input[type='range']::-ms-track {
    -webkit-appearance: none;
    background: transparent;
    border: 0 none;
    color: transparent;
    cursor: pointer;
    overflow: visible;
    width: 100%;
  }

  input[type='range']::-ms-fill-lower {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0 none;
    color: transparent;
  }

  input[type='range']::-ms-fill-upper {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0 none;
    color: transparent;
  }

  input[type='range']::-ms-tooltip {
    display: none;
  }

  input[type='range']::-moz-range-track {
    -webkit-appearance: none;
    background: transparent;
    border: 0 none;
    color: transparent;
    cursor: pointer;
    overflow: visible;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: inherit;
    font-weight: normal;
    line-height: inherit;
    margin: 0;
    padding: 0;
  }

  sup {
    font-size: 50%;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .flickity-viewport {
    height: 400px;
    &:focus {
      outline: none;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    opacity: 0; 
  }

  .flickity-enabled:focus,
  .flickity-button:focus {
    outline: none;
  }

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -45px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;

    &:focus {
      outline: none;
    }
  }
  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }
  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
    transition: opacity 150ms ease;
  }
  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }

  .intercom-launcher-frame {
  }

  html.intercom-mobile-messenger-active > body.flexible-body {
    height: auto !important;
  }

  .intercom-messenger-frame {
  }
`;

export default globalCSS;
