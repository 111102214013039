import color from "./color";
import ease from "./ease";
import text from "./text";
import layout from "./layout";
import zIndex from "./zIndex";

const theme = {
  color,
  ease,
  layout,
  text,
  zIndex,
};

export default theme;
