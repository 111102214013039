import styled from 'styled-components';
import { Title } from '../../shared/Typography/Title';

export const UpdateAddressWrapper = styled.main`
  margin-bottom: 80px;
  .input-upper {
  }

  .update-address-form,
  .input-upper,
  .input-lower {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .form-group {
    display: flex;
    gap: 16px;
  }

  ${Title} {
    margin-bottom: 40px;
  }

  select {
    background: white;
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }

  .button-area {
    margin-top: 48px;
    display: flex;
    gap: 16px;
  }

  .available-states-input {
    height: ${({ theme }) => theme.layout.spacing.formElementBaseHeight};
    width: 100%;
    border-radius: ${({ theme }) => theme.layout.borderRadius};
    padding: 0 8px;
    display: block;
    -webkit-appearance: menulist;
    border: 2px solid
      ${({ theme, isError }) =>
        isError ? theme.color.utilityError : theme.color.interface200};
    font-size: 16px;
    color: ${({ theme }) => theme.color.interface800};
  }

  @media (min-width: 1024px) {
    .form-group {
      display: block;
    }
    .input-upper {
      display: grid;
      gap: 16px;
      grid-template-columns: 2fr 1fr;
    }
    .input-lower {
      gap: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .available-states-error {
    border: 2px solid ${({ theme }) => theme.color.utilityError};
  }
  .available-states-placeholder {
    color: ${({ theme }) => theme.color.interface500};
    option {
      color: ${({ theme }) => theme.color.interface800};
    }
    :hover {
      .dropdown-option-disabled {
        color: ${({ theme }) => theme.color.interface500};
      }
    }
  }
  .dropdown-option-disabled {
    color: ${({ theme }) => theme.color.interface600};
  }
`;
