const LOCAL_KEYS = {
  ADDRESS: 'fl.addressData',
  DID_MANUALLY_ENTER_ADDRESS: 'fl.didManuallyEnterAddress',
  FLOW_DATA: 'fl.getAQuoteFlowData',
  FLOW_STEP: 'fl.getAQuoteActiveStep',
  FLOW_LINK: 'fl.flowLink',
  FLOW_DONE: 'fl.flowDone',
  FLOW_TYPE: 'fl.flowType',
  FLOW_SOURCE: 'fl.flowSource',
  OFFER_TYPE_ID: 'fl.offerTypeId',
  FL_ANSWERS: 'fl.answers',
  FLOW_ADDRESS_QUESTIONS: 'fl.addressQuestions',
  FLOW_FORM_PROGRESS: 'fl.formProgress',
  SELECTED_OFFER_TYPE: 'fl.selectedOfferType',
  OFFER_WISHLIST_DATA: 'fl.offerWishlistData',
  OFFER_WISHLIST_SELECTED_OFFER: 'fl.offerWishlistSelectedOffer',
  SHOPPING_CART: 'fl.shoppingCart',
  IS_CHECKOUT: 'fl.isCheckout',
  USER_INFORMATION: 'fl.userInformation',
  FLEX_CHECK_FORM: "fl.flexCheckForm",
  FLEX_CHECK_FORM_STATE: "fl.flexCheckFormState",
} as const;

export default LOCAL_KEYS;
