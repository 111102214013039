const BREAKPOINTS = {
  xSmallOnly: "(max-width: 639px)",
  xSmall: "(min-width: 375px)",
  small: "(min-width: 640px)",
  smallOnly: "(min-width: 640px) and (max-width: 959px)",
  xSmallAndSmall: "(max-width: 959px)",
  medium: "(min-width: 960px)",
  mediumOnly: "(min-width: 960px) and (max-width: 1279px)",
  large: "(min-width: 1280px)",
  largeOnly: "(min-width: 1280px) and (max-width: 1919px)",
  xLarge: "(min-width: 1920px)",
  tabletSmall: "(min-width: 768px)",
  tabletAndSmaller: "(max-width: 768px)",
  customLarge: "(min-width: 1372px)",
};

type Breakpoints = typeof BREAKPOINTS;

export default BREAKPOINTS;
export type { Breakpoints };
