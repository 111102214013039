import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  TOffer,
  TOfferTerm,
} from 'src/components/OfferWishlist/types/offer-wishlist';
import { SEARCH_PARAMS } from 'src/constants/searchParams';
interface Props {}

type TOfferType = {
  description: string;
  id: string;
  contentful_id: string;
  slug: string;
  title: string;
  themeColor: string;
  isSelected: boolean;
  note: string;
  pros: string[];
  offerTerms: TOfferTerm[];
  agentDescription: {
    agentDescription: string | null;
  };
};

function useOfferTypes() {
  const {
    content: { offerTypes },
  } = useStaticQuery(graphql`
    query {
      content: contentfulContentContainer(slug: { eq: "all-offer-types" }) {
        offerTypes: content {
          ... on ContentfulPageTemplate {
            description
            id
            contentful_id
            slug
            title
            contentful_id
            themeColor
            pros
            cons
            offerTerms {
              title
              description
              popupText
              customNoValueErrorMessage
              inputType
              maxNumbers
              specifyTermsAgentText {
                childMarkdownRemark {
                  rawMarkdownBody
                  html
                }
              }
              termDescription {
                termDescription
                childMarkdownRemark {
                  rawMarkdownBody
                  html
                }
              }
              specifyTermsText {
                childMarkdownRemark {
                  rawMarkdownBody
                  html
                }
              }
              salesforceId
              dateSelections
              alternatives {
                value
                isSelected
                isSpecific
                duration
                
              }
            }
            agentDescription {
              agentDescription
            }
          }
        }
      }
    }
  `) as {
    content: {
      offerTypes: TOfferType[];
    };
  };

  function isValidOfferType(offerTypeId: string): boolean {
    return offerTypes.some(
      ({ contentful_id }: TOfferType) => contentful_id === offerTypeId
    );
  }

  function getOfferTypeByName(offerTypeName: string): TOfferType | undefined {
    return offerTypes.find(({ title }: TOfferType) => title === offerTypeName);
  }

  function getOfferTypeNameById(offerTypeId: string): string | undefined {
    const offerType = offerTypes.find((offerType: TOfferType) => {
      return offerType.contentful_id === offerTypeId;
    });

    if (offerType) {
      return offerType.title;
    }

    return undefined;
  }

  return {
    offerTypes,
    getOfferTypeNameById,
    isValidOfferType,
    getOfferTypeByName,
  };
}

export default useOfferTypes;
