import React, { useState } from 'react';
import { Input, InputControl, InputError } from '../../shared/Input/Input.tsx';
import { Label } from '../../shared/Label/Label';
import { Container } from '../../shared/Container/Container';
import { ButtonPrimary, ButtonSecondary } from '../../shared/Buttons/Buttons';
import { UpdateAddressWrapper } from './update-address.style';
import { Title } from '../../shared/Typography/Title';
import AVAILABLE_STATES from './states.json';
import { useFormik } from 'formik';
import { useFormikContext } from 'formik';

function UpdateAddress({
  onUpdateAddressSuccess,
  onCancelClick,
  shouldDisplayCancelButton = true,
  title = 'Need to update your address?',
}) {
  const formik = useFormik({
    initialValues: {
      streetName: '',
      unitNumber: '',
      city: '',
      state: '',
      zip: '',
      errors: '',
    },

    validate: () => {
      const errors = {};
      if (!formik.values.streetName) {
        errors.streetName = 'Required';
      } else if (formik.values.streetName.length < 3) {
        errors.streetName = 'Required';
      }

      if (!formik.values.city) {
        errors.city = 'Required';
      } else if (formik.values.city.length < 2) {
        errors.city = 'Required';
      }

      if (!formik.values.state) {
        errors.state = 'Required';
      }

      if (!formik.values.zip) {
        errors.zip = 'Required';
      } else if (formik.values.zip.length > 5) {

      }

      return errors;
    },

    onSubmit: (values) => {
      if (formik.isValid) {
        const streetName = `${formik.values.streetName} ${formik.values.unitNumber}`;

        const addressData = {
          address: streetName,
          street: streetName,
          unitNumber: formik.values.unitNumber,
          city: formik.values.city,
          state: formik.values.state,
          zip: formik.values.zip,
        };

        onUpdateAddressSuccess({
          ...addressData,
          lat: -999,
          lng: -999,
        });
      }
    },
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const isStreetnameError =
    formik.touched.streetName && formik.errors.streetName;
  const isCityError = formik.touched.city && formik.errors.city;
  const isStateError = formik.touched.state && formik.errors.state;
  const isZipError = formik.touched.zip && formik.errors.zip;

  return (
    <UpdateAddressWrapper>
      <Container>
        <Title size="medium">We didn't get that. What’s the property address?</Title>

        <form onSubmit={formik.handleSubmit}>
          <div className="update-address-form">
            <div className="input-upper">
              <InputControl>
                <Label>Street Name</Label>
                <Input
                  onFocus={() => formik.setFieldError('streetName', '')}
                  onBlur={formik.handleBlur}
                  isError={isStreetnameError}
                  value={formik.values.streetName}
                  onChange={formik.handleChange}
                  name="streetName"
                />
                {isStreetnameError && (
                  <InputError>{formik.errors.streetName}</InputError>
                )}
              </InputControl>
              <InputControl>
                <Label>Unit Number</Label>
                <Input
                  value={formik.values.unitNumber}
                  name="unitNumber"
                  onChange={formik.handleChange}
                />
              </InputControl>
            </div>
            <div className="input-lower">
              <InputControl>
                <Label>City</Label>
                <Input
                  onFocus={() => formik.setFieldError('city', '')}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  name="city"
                  onChange={formik.handleChange}
                  isError={isCityError}
                />
                {isCityError && <InputError>{formik.errors.city}</InputError>}
              </InputControl>
              <InputControl>
                <Label>Zip code</Label>
                <Input
                  onFocus={() => formik.setFieldError('zip', '')}
                  onBlur={formik.handleBlur}
                  value={formik.values.zip}
                  name="zip"
                  onChange={(event) => {
                    if (event.target.value.length <= 5) {
                      formik.handleChange(event);
                    }
                  }}
                  pattern="[0-9]+"
                  isError={isZipError}
                  type="number"
                />
                {isZipError && <InputError>{formik.errors.zip}</InputError>}
              </InputControl>
              <InputControl>
                <Label>State</Label>
                <select

                  className={`available-states-input ${isStateError && "available-states-error"} ${formik.values.state === "" && "available-states-placeholder"} `}
                  onFocus={() => formik.setFieldError('state', '')}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                  name="state"
                  onChange={formik.handleChange}
                >
                  <option
                    className="dropdown-option-disabled"
                    value=""
                    disabled
                  >
                    Select
                  </option>
                  {AVAILABLE_STATES.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
                <InputError>{isStateError && formik.errors.state}</InputError>
              </InputControl>
            </div>
          </div>
          <div className="button-area">
            {shouldDisplayCancelButton && (
              <ButtonSecondary onClick={onCancelClick}>Cancel</ButtonSecondary>
            )}
            <ButtonPrimary type="submit">Update</ButtonPrimary>
          </div>
        </form>
      </Container>
    </UpdateAddressWrapper>
  );
}

export default UpdateAddress;
