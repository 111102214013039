import { css } from "styled-components";
import { media } from "src/utils/mixins";

/* ************************************************************
Font types
************************************************************ */
const centurySchoolbook = css`
  font-family: "CenturySchoolbook", serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
`;

const graphikMedium = css`
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
`;

const graphikRegular = css`
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
`;

const graphikSemibold = css`
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
`;

const caveat = css`
  font-family: "Caveat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
`

/* ************************************************************
Text styles
************************************************************ */

const h1 = css`
  ${centurySchoolbook}

  font-size: 48px;
  letter-spacing: -1.1px;
  line-height: 1.2;

  ${media.medium`
    font-size: 72px;
  `}

  ${media.xLarge`
    font-size: 84px;
  `}
`;

const h2 = css`
  ${centurySchoolbook}

  font-size: 32px;
  letter-spacing: -1.1px;
  line-height: 1.2;

  ${media.small`
    font-size: 48px;
  `}

  ${media.medium`
    font-size: 56px;
  `}

  ${media.xLarge`
    font-size: 64px;
  `}
`;

const h3 = css`
  ${centurySchoolbook}

  font-size: 28px;
  letter-spacing: -1.1px;
  line-height: 1.2;

  ${media.small`
    font-size: 32px;
  `}

  ${media.large`
    font-size: 40px;
  `}

  ${media.xLarge`
    font-size: 46px;
  `}
`;

const h4 = css`
  ${centurySchoolbook}

  font-size: 28px;
  letter-spacing: -1.1px;
  line-height: 1.2;

  ${media.large`
    font-size: 32px;
  `}
`;

const h5 = css`
  ${centurySchoolbook}

  font-size: 24px;
  letter-spacing: -1.1px;
  line-height: 1.2;

  ${media.large`
    font-size: 28px;
  `}

  ${media.xLarge`
    font-size: 32px;
  `}
`;

const h6 = css`
  ${centurySchoolbook}

  font-size: 20px;
  letter-spacing: -1.1px;
  line-height: 1.2;

  ${media.large`
    font-size: 24px;
  `}

  ${media.xLarge`
    font-size: 28px;
  `}
`;

const p = css`
  ${graphikRegular}

  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  ${media.xLarge`
    font-size: 18px;
  `}
`;

const btnText = css`
  ${graphikSemibold}

  font-size: 12px;
  line-height: 1;

  ${media.xSmall`
  font-size: 16px;
  `}
`;

const handwritten = css`
  font-family: "Caveat";
  font-size: 30px;

  ${media.xSmall`
    font-size: 36px;
  `}
`;

export default {
  centurySchoolbook,
  graphikMedium,
  graphikRegular,
  graphikSemibold,
  handwritten,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  btnText,
};
