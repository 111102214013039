import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import normalizeCSS from 'src/utils/normalizeCSS';
import globalCSS from 'src/utils/globalCSS';
import googleMapsCSS from 'src/utils/googleMapsCSS';

import 'src/styles/fonts.css';

const Style = createGlobalStyle`
  ${normalizeCSS()}
  ${globalCSS()}
  ${googleMapsCSS()}
`;

const GlobalStyles = ({
  children,
}) => (
  <div>
    <Style />
    { children }
  </div>
);

GlobalStyles.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default GlobalStyles;
