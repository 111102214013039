import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import ContextConsumer from "./src/context/ContextConsumer";
import { AppContextProvider } from "./src/context";
import { CourseProvider } from "src/context/Course/CourseContext";
import theme from "./src/theme";
import GlobalStyles from "./src/components/GlobalStyles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  OfferTypeContext,
  OfferTypeProvider,
} from "./src/context/OfferTypeContext/OfferTypeContext";
import { ShoppingCartProvider } from "src/components/ShoppingCart/ShoppingCart.context.tsx";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>
    <QueryClientProvider client={queryClient}>
      <OfferTypeProvider>
        <CourseProvider>
          <ShoppingCartProvider>
            <ThemeProvider theme={theme}>{element}</ThemeProvider>
          </ShoppingCartProvider>
        </CourseProvider>
      </OfferTypeProvider>
    </QueryClientProvider>
  </AppContextProvider>
);

export const wrapPageElement = ({ element }) => (
  <GlobalStyles>{element}</GlobalStyles>
);

export default {};
