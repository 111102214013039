import React, { createContext, useState, useEffect } from 'react';

const CourseContext = createContext();

function CourseProvider({ children }) {
  const [selectedTabs, setSelectedTabs] = useState([]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const chapterParam = searchParams.get('chapter');
    const chapterParamAsNumber = parseInt(chapterParam, 10);

    if (!isNaN(chapterParamAsNumber)) {
      setSelectedTabs([chapterParamAsNumber]);
    }
  }, []);

  function openTab(index) {
    setSelectedTabs((prev) => [...prev, index]);
  }

  function toggleTabs(index) {
    if (selectedTabs.includes(index)) {
      setSelectedTabs(selectedTabs.filter((tab) => tab !== index));
    } else {
      setSelectedTabs([...selectedTabs, index]);
    }
  }

  function getSectionParam() {
    if (typeof window === 'undefined') {
      return 0;
    }
    const searchParams = new URLSearchParams(location.search);

    const param = searchParams.get('section');

    const paramAsNumber = parseInt(param);

    if (typeof paramAsNumber === 'undefined') {
      return 0;
    }

    if (!paramAsNumber) {
      return 0;
    }

    return paramAsNumber;
  }

  function getChapterParam() {
    if (typeof window === 'undefined') {
      return 0;
    }
    const searchParams = new URLSearchParams(location.search);

    const param = searchParams.get('chapter');
    const paramAsNumber = parseInt(param);

    if (typeof paramAsNumber === 'undefined') {
      return 0;
    }

    if (!paramAsNumber) {
      return 0;
    }

    if (isNaN(paramAsNumber)) {
      return 0;
    }

    return paramAsNumber;
  }

  return (
    <CourseContext.Provider
      value={{
        selectedTabs,
        toggleTabs,
        openTab,
        section: getSectionParam,
        chapter: getChapterParam,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
}

export { CourseContext, CourseProvider };
