import React from 'react';
import PropTypes from 'prop-types';

type Props = {
  className?: string;
};

const IconClose = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 17 17">
    <g>
      <path d="M.5922 2.0064l14.4014 14.4014 1.4142-1.4142L2.0064.5922z" />
      <path d="M16.4078 2.0064L2.0064 16.4078.5922 14.9936 14.9936.5922z" />
    </g>
  </svg>
);

export default IconClose;
