const colors = {
  interface50: '#fafafa',
  interface100: '#f5f5f5',
  interface200: '#e5e5e5',
  interface300: '#d4d4d4',
  interface400: '#a3a3a3',
  interface500: '#737373',
  interface600: '#525252',
  interface700: '#404040',
  interface800: '#262626',
  interface900: '#171717',
  primary50: '#F5F9F7',
  primary100: '#EBF2F0',
  primary200: '#E1ECE8',
  primary400: '#CDDFD9',
  primary500: '#358267',
  primary600: '#2E7059',
  primary700: '#296550',
  primary800: '#235745',
  primary900: '#173A2E',

  white: '#FFFFFF',
  purple: '#6B7DC4',
  utilityError: '#2E7059',
  utilityBlue: '#2E7059',
  utilityOrange: '#ca8629',
  utilityOrangeLight: '#EDD892',
  utilityRedVibrant: '#E8241C',
};

export default colors;
