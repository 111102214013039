export const getFormattedPhoneNumber = value => {
  let formattedVal = value;
  const numbers = formattedVal.replace(/\D/g, '');

  if (formattedVal.length === 5 && numbers.length === 3) {
    formattedVal = numbers.slice(0, 2);
  } else if (numbers.length >= 3 && numbers.length < 7) {
    formattedVal = `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
  } else if (numbers.length >= 7) {
    formattedVal = `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
  }

  return formattedVal;
};
