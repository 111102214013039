const QUOTE_FLOW_STEPS = {
  OTHER: 'other',
  CONTACT: 'contact',
  DONE: 'done',
  OFFERS: 'offers',
  INTRO: 'intro',
  QUESTIONS: "questions"
};

export default QUOTE_FLOW_STEPS;
