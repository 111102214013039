import styled, { css, keyframes } from 'styled-components';
import { Title } from '../../shared/Typography/Title';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const blink = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
`;

export const FreeOfferQuestionaireWrapper = styled.div`
  max-width: 404px;
  margin: 0 auto;
  animation: ${fadeIn} 0.3s ease-in-out;
  ${Title} {
    margin-bottom: 40px;
    text-align: left;
  }
  .property-condition-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    label {
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    ${Title} {
      margin-bottom: 80px;
      text-align: center;
    }

    margin-bottom: 40px;
  }
`;

export const AnswerButton = styled.button`
  min-height: 74px;
  max-width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  height: 100%;
  text-align: left;
  border: 2px solid
    ${({ theme, isSelected, isError }) => {
      if (isError) {
        return theme.color.utilityError;
      }
      return isSelected ? theme.color.primary500 : theme.color.interface200;
    }};
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  padding: ${({ theme }) => theme.layout.spacing.spacing04};
  display: flex;
  align-items: center;
  gap: 24px;
  animation: ${({ isSelected, shouldAnimate = true }) =>
    isSelected && shouldAnimate
      ? css`
          ${blink} 0.25s ease-in-out;
        `
      : 'none'};

  animation-iteration-count: 2;
  transition: all 0.2s ease-in-out;

  > div {
  }
  > * {
    cursor: pointer;
  }
  :hover {
    @media (hover: hover) {
      border: 2px solid ${({ theme }) => theme.color.primary400};
    }
  }

  :disabled {
    transition: all 0.2s ease-in-out;
    border: 2px solid ${({ theme }) => theme.color.interface100};
    cursor: not-allowed;
    .radio-button-button {
      cursor: not-allowed;
    }
    > * {
      cursor: not-allowed;
    }
    label {
      color: ${({ theme }) => theme.color.interface300};
    }
  }
  .property-relationship-radio-button-label {
    color: ${({ theme }) => theme.color.interface800};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 16px;
  }

  label {
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.color.interface700};
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    span {
      display: block;
      margin-bottom: 8px;
    }
  }
`;
