import styled, { css } from 'styled-components';

interface StyledProps {
  buttonHoverColor?: string;
  buttonColor?: string;
  size?: 'xl' | 'large' | 'medium' | 'small';
  shouldRemoveDisabledStyles?: boolean;
}

const Button = styled.button<StyledProps>`
  height: ${({ theme, size = 'medium' }) => {
    if (size === 'xl') {
      return '80px';
    }

    if (size === 'large') {
      return theme.layout.spacing.formElementBaseHeightLarge;
    }

    if (size === 'medium') {
      return theme.layout.spacing.formElementBaseHeight;
    }

    return '40px';
  }};
  padding: 0 ${({ theme }) => theme.layout.spacing.spacing06};
  font-weight: 500;
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  transition: all 0.1s ease-in-out;
  gap: 8px;
  font-size: ${({ size }) => {
    if (size === 'xl') {
      return '18px';
    }

    if (size === 'large') {
      return '18px';
    }
    return '16px';
  }};
`;

const ButtonPrimary = styled(Button)<StyledProps>`
  background: ${({ theme, buttonColor }) =>
    buttonColor ? buttonColor : theme.color.primary500};
  color: white;
  text-decoration: none;
  :disabled {
    ${({ shouldRemoveDisabledStyles = false }) =>
      shouldRemoveDisabledStyles
        ? css`color: white;
        background: ${({ theme }) => theme.color.primary500}}
        `
        : css`
            background: ${({ theme }) => theme.color.interface300};
            color: ${({ theme }) => theme.color.interface800};
            cursor: not-allowed;
            :hover {
              background: ${({ theme }) => theme.color.interface300};

              color: ${({ theme }) => theme.color.interface800};
            }
          `}
  }

  :hover {
    background: ${({ theme, buttonHoverColor }) =>
      buttonHoverColor ? buttonHoverColor : theme.color.primary600};
  }
`;

const ButtonSecondary = styled(Button)`
  border: 2px solid ${({ theme }) => theme.color.primary500};
  color: ${({ theme }) => theme.color.primary600};
  :hover {
    border: 2px solid ${({ theme }) => theme.color.primary600};
    background: ${({ theme }) => theme.color.interface50};
  }
`;

const ButtonTertiary = styled.button<StyledProps>`
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary600};
  font-size: 16px;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.primary700};
  }

  height: ${({ theme, size = 'medium' }) => {
    if (size === 'xl') {
      return '80px';
    }

    if (size === 'large') {
      return theme.layout.spacing.formElementBaseHeightLarge;
    }

    if (size === 'medium') {
      return theme.layout.spacing.formElementBaseHeight;
    }

    return '40px';
  }};

  font-size: ${({ size }) => {
    if (size === 'xl') {
      return '18px';
    }

    if (size === 'large') {
      return '18px';
    }
    return '16px';
  }};
`;

const ButtonPrimaryInverted = styled(Button)`
  background: ${({ theme }) => theme.color.primary100};
  color: ${({ theme }) => theme.color.primary800};
  text-decoration: none;
  :hover {
    background: ${({ theme }) => theme.color.primary200};
  }
`;

export {
  ButtonPrimary,
  ButtonTertiary,
  ButtonSecondary,
  ButtonPrimaryInverted,
};
