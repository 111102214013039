import React from 'react';
import styled from 'styled-components';

export const LabelWrapper = styled.label<StyledProps>`
  font-size: 16px;
  color: ${({ theme }) => theme.color.interface800};
  font-weight: ${({ fontWeight }) => fontWeight};
  display: block;

  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};

  .is-required-label {
    color: ${({ theme }) => theme.color.utilityError};
  }
`;

type StyledProps = {
  fontWeight: 400 | 500;
  isClickable?: boolean;
};

type Props = {
  children: React.ReactNode;
  isRequired?: boolean;
  htmlFor?: string;
  className?: string;
  fontWeight?: 400 | 500;
  isClickable?: boolean;
};

function Label({
  children,
  isRequired,
  htmlFor,
  className,
  isClickable,
  fontWeight = 500,
}: Props) {
  return (
    <LabelWrapper
      isClickable={isClickable}
      fontWeight={fontWeight}
      className={className ?? ''}
      htmlFor={htmlFor}
    >
      {children}
      {isRequired && <span className="is-required-label">*</span>}
    </LabelWrapper>
  );
}

export { Label };
