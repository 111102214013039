import React from 'react';
import PropTypes from 'prop-types';

import { BarOuter, BarInner } from './style';
import styled, { keyframes } from 'styled-components';

const scaleUpAnimation = keyframes`
  from {
    transform: scaleX(0.5);
    transform: scaleY(0.5);
  }

  to {
    transform: scaleX(1);
    transform: scaleY(1);

  }
`;

const BannerFade = styled.div`
  animation: ${scaleUpAnimation} 0.2s linear;
`;

const ProgressBar = ({
  currentStepNumber,
  stepTotal,
  shouldRenderTooltip = true,
  customPercentage,
}) => {
  let percentageCompleted = (currentStepNumber / stepTotal) * 100;
  const previousWidth = (currentStepNumber - 1 / stepTotal) * 100;
  const isIncompleteForm = percentageCompleted > 0 && percentageCompleted < 100;

  percentageCompleted > 100
    ? (percentageCompleted = 100)
    : (percentageCompleted = percentageCompleted);
  return (
    <BarOuter previousWidth={previousWidth} barWidth={percentageCompleted}>
      <BarInner previousWidth={previousWidth} barWidth={percentageCompleted} />
      {isIncompleteForm && shouldRenderTooltip && (
        <BannerFade key={`${customPercentage}`}>
          <div className="progress-bar-progress-percent">
            <div className="progress-bar-arrow"></div>
            {customPercentage
              ? `${customPercentage} % done`
              : `${Math.round(percentageCompleted.toFixed() / 10) * 10} % done`}
          </div>
        </BannerFade>
      )}
    </BarOuter>
  );
};

ProgressBar.propTypes = {
  currentStepNumber: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
};

export default ProgressBar;
