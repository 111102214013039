
import styled from 'styled-components'
export const Container = styled.div`
max-width:1280px;
margin: 0 auto; 
width: 90%; 
height: 100%; 
@media(min-width:1280px) { 
    width: 100%; 
}
`
