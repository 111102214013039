import styled from 'styled-components';
import { Container } from '../../shared/Container/Container';
import { Title } from '../../shared/Typography/Title';
import { ButtonPrimary } from '../../shared/Buttons/Buttons';

export const PropertyRelationshipWrapper = styled.div`
  padding-top: 80px;

  .property-relationship-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .property-relationship-bottom {
    ${Title} {
      margin-bottom: 40px;
      max-width: 600px;
    }
  }
  .property-relationship-address-confirmation {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
    address {
      font-weight: 400;
      font-size: 16px;
      color: ${({ theme }) => theme.color.interface800};
    }
  }

  @media (min-width: 640px) {
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: Center;

    .property-relationship-bottom {
      ${Title} {
        text-align: center;
        margin-bottom: 80px;
      }
    }
    .property-relationship-address-confirmation {
      justify-content: center;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 40px;
    margin-bottom: 0;
  }
`;

export const PropertyRelationshipRadioButton = styled.button`
  min-height: 74px;
  height: 100%;
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.color.primary500 : theme.color.interface200};
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  ${({ theme }) => theme.layout.spacing.spacing04};
  margin-left: 16px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  padding: ${({ theme }) => theme.layout.spacing.spacing04};
  .property-relationship-text-content {
    display: flex;
    align-items: Center;
    height: 100%;
  }
  label {
    cursor: pointer;
    pointer-events: none;
    display: block;
  }
  :hover {
    border: 2px solid ${({ theme }) => theme.color.interface500};
  }
  .property-relationship-radio-button-label {
    color: ${({ theme }) => theme.color.interface800};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 16px;
  }

  .property-relationship-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 40px;
    label {
      font-weight: 500;
      font-size: 16px;
      color: ${({ theme }) => theme.color.interface700};
      display: block;
      width: 100%;
      span {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
  @media (min-width: 768px) {
    margin-left: 0;
  }
`;

export const EditAddressMapWrapper = styled.div`
  padding: 64px 0px;
  .edit-address-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .edit-address-map {
    width: 100%;
    height: 250px;
    border-radius: ${({ theme }) => theme.layout.borderRadius};
  }

  .edit-address-text {
    margin-top: 32px;
    margin-bottom: 64px;

    ${Title} {
      margin-bottom: 8px;
      text-align: center;
      color: ${({ theme }) => theme.color.interface800};
    }

    p {
      font-size: 16px;
      text-align: center;
      color: ${({ theme }) => theme.color.interface800};
    }
  }

  @media (min-width: 768px) {
    padding: 64px 24px;
  }
`;
