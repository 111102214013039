import React from 'react';
import { Container } from '../../shared/Container/Container';
import { Label } from '../../shared/Label/Label';
import { FakeRadioButton } from '../../shared/RadioButton/RadioButton';
import { Title } from '../../shared/Typography/Title';
import {
  AnswerButton,
  FreeOfferQuestionaireWrapper,
} from './free-offer-questionaire.style';

function FreeOfferQuestionaire({ onChange, value, question, answers }) {
  return (
    <>
      <FreeOfferQuestionaireWrapper>
        <Container>
          <Title size="medium">{question}</Title>
          <form className="property-condition-list">
            {answers.map((answer) => (
              <AnswerButton
                shouldAnimate={false}
                type="button"
                key={answer}
                isSelected={value === answer}
                onClick={() => {
                  onChange(answer);
                }}
                className="questionaire-answer-wrapper"
              >
                <FakeRadioButton checked={value === answer} />
                <Label>{answer}</Label>
              </AnswerButton>
            ))}
          </form>
        </Container>
      </FreeOfferQuestionaireWrapper>
    </>
  );
}

export default FreeOfferQuestionaire;
