import React from 'react';
import PropTypes from 'prop-types';

const Lock = ({
  className,
}) => (
  <svg className={className} viewBox='0 0 54 72.5'>
    <path d='M49.5 30.3v-1.9c.1-8.9.1-17.4-5.4-23C40.5 1.8 35 0 27.3 0 5.8 0 5.1 15.7 5.1 30.1c-3 1.2-5.1 3.7-5.1 6.5v28.6c0 4 4.1 7.3 9.1 7.3h35.8c5 0 9.1-3.3 9.1-7.3V36.6c0-2.7-1.8-5.1-4.5-6.3zM27 58.8h-6.2l3.5-7c-1.8-1-3-2.9-3-5 0-3.2 2.6-5.7 5.7-5.7s5.7 2.6 5.7 5.7c0 2.2-1.2 4.1-3 5l3.5 7H27zm13.9-30.5v1H13.7c.1-15.6 1.6-20.7 13.6-20.7 5.3 0 8.8.9 10.7 2.9 3 3 2.9 9.4 2.9 16.8z' />
  </svg>
);

Lock.propTypes = {
  className: PropTypes.string,
};

export default Lock;
