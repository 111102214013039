import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

interface StyledProps {
  checked?: boolean;
}

export const RadioButtonWrapper = styled.div<StyledProps>`
  position: relative;
  cursor: pointer;
  .radio-button-button {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${({ theme, checked }) =>
    checked ? theme.color.primary500 : 'none'};
    border: 2px solid
      ${({ theme, checked }) =>
    checked ? theme.color.primary500 : theme.color.interface200};
    /*     display: inline-flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    cursor: pointer;
    .radio-button-dot {
      cursor: pointer;

      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    z-index: 9999;
    max-width: 100%;
  }
`;

interface Props {
  value?: string | number;
  checked?: boolean | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  defaultChecked?: boolean | undefined;
  id?: string;
  readOnly?: boolean;
}

function RadioButton({
  value,
  checked,
  onChange,
  name,
  defaultChecked,
  id,
  readOnly = false,
}: Props) {
  return (
    <RadioButtonWrapper checked={checked}>
      <div className="radio-button-button">
        {checked && <div className="radio-button-dot"></div>}
      </div>
      <input
        id={id}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        name={name}
      />
    </RadioButtonWrapper>
  );
}

type FakeProps = {
  checked?: boolean;
};

function FakeRadioButton({ checked }: FakeProps) {
  return (
    <RadioButtonWrapper checked={checked}>
      <div className="radio-button-button">
        <div className="radio-button-dot"></div>
      </div>
    </RadioButtonWrapper>
  );
}

export { RadioButton, FakeRadioButton };
