import React, { useEffect, useState } from 'react';
import { ButtonTertiary } from '../../shared/Buttons/Buttons';
import { Container } from '../../shared/Container/Container';
import { Input, InputControl, InputError } from '../../shared/Input/Input';
import { Label } from '../../shared/Label/Label';
import Modal from '../../shared/Modal/Modal';
import { FakeRadioButton } from '../../shared/RadioButton/RadioButton';
import { Title } from '../../shared/Typography/Title';
import { PROPERTY_RELATIONSHIP_ANSWERS } from '../GetFreeOffer.container';
import EditAddress from './EditAddress';
import {
  PropertyRelationshipRadioButton,
  PropertyRelationshipWrapper,
} from './property-relationship.style';
import LOCAL_KEYS from '../../../constants/localStorage';




function PropertyRelationship({
  propertyRelationship,
  onPropertyRelationshipChange,
  onInputEnterClick,
  specificRelationship,
  onSpecificRelationshipChange,
  addressLat,
  addressLng,
  address,
  onFixAddressClick,
}) {
  const [isEditingAddress, setIseditingAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const hasClarifiedOwnership =
    (propertyRelationship === PROPERTY_RELATIONSHIP_ANSWERS.other &&
      specificRelationship.length > 2) ||
    propertyRelationship !== PROPERTY_RELATIONSHIP_ANSWERS.other;
  function validateClarifiedOwnership() {
    if (!hasClarifiedOwnership) {
      setErrorMessage('Please explain your relationship to this property.');
    } else {
      setErrorMessage('');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PropertyRelationshipWrapper>
      <Container>
        <div className="property-relationship-address-confirmation">
          <address>{address}</address>
          <ButtonTertiary onClick={() => setIseditingAddress(true)}>
            Edit
          </ButtonTertiary>
          {isEditingAddress && (
            <Modal
              maxWidth="500px"
              onOutsideClick={() => setIseditingAddress(false)}
            >
              <EditAddress
                onDontEditAddressClick={() => setIseditingAddress(false)}
                onFixAddressClick={() => onFixAddressClick()}
                lat={addressLat}
                lng={addressLng}
                address={<div>{address}</div>}
              />
            </Modal>
          )}
        </div>
        <div className="property-relationship-bottom">
          <Title size='medium'>What is your relationship to this property?</Title>
          <div className="property-relationship-list">
            {Object.values(PROPERTY_RELATIONSHIP_ANSWERS).map(
              (relationship) => {
                const isSecialRelationship =
                  relationship === PROPERTY_RELATIONSHIP_ANSWERS.other &&
                  propertyRelationship === PROPERTY_RELATIONSHIP_ANSWERS.other;

                return (
                  <PropertyRelationshipRadioButton
                    key={relationship}
                    errorMessage={errorMessage}
                    onClick={(event) => {
                      onPropertyRelationshipChange(relationship);
                    }}
                    isSelected={propertyRelationship === relationship}
                  >
                    <div className="property-relationship-text-content">
                      <label
                        className="property-relationship-radio-button-label"
                        htmlFor=""
                      >
                        <FakeRadioButton
                          checked={propertyRelationship === relationship}
                        />
                        {relationship}
                      </label>
                    </div>
                    <div>
                      {isSecialRelationship && (
                        <div className="property-relationship-input">
                          <InputControl>
                            <Label>Describe your relationship</Label>
                            <Input
                              isError={errorMessage.length > 0}
                              onBlur={() => {
                                validateClarifiedOwnership();
                              }}
                              onFocus={() => setErrorMessage('')}
                              value={specificRelationship}
                              onKeyUp={(event) => {
                                if (event.key === 'Enter') {
                                  onSpecificRelationshipChange(
                                    event.currentTarget.value
                                  );

                                  if (hasClarifiedOwnership) {
                                    onInputEnterClick();
                                    return;
                                  } else {
                                    validateClarifiedOwnership();
                                  }
                                }
                              }}
                              onChange={(event) => {
                                onSpecificRelationshipChange(
                                  event.currentTarget.value
                                );
                              }}
                            />
                            {errorMessage && (
                              <InputError>{errorMessage}</InputError>
                            )}
                          </InputControl>
                        </div>
                      )}
                    </div>
                  </PropertyRelationshipRadioButton>
                );
              }
            )}
          </div>
        </div>
      </Container>
    </PropertyRelationshipWrapper>
  );
}

export default PropertyRelationship;
