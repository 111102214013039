import styled from 'styled-components';
import { ButtonPrimary } from '../../shared/Buttons/Buttons';

export const ContactFormWrapperInner = styled.div`
  .contact-form-inputs-wrap {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  ${ButtonPrimary} {
    margin-top: 32px;
  }
  .contact-form-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .contact-form-google-login-wrap {
    margin-bottom: 24px;
  }
`;
