import React, { useState } from 'react';
import store from 'store2';
import {
  QUESTIONS,
  QUESTION_IDS,
} from '../../components/OfferTypeQuestionaire/offer-type.constants';
import LOCAL_KEYS from '../../constants/localStorage';
import { set } from 'lodash';
const OfferTypeContext = React.createContext();

function OfferTypeProvider({ children }) {
  const [address, setAddress] = useState({
    address: { 
      
    }
  })
  const [activeQuestion, setActiveQuestion] = useState(() => {
    return QUESTION_IDS.WHY_TRANSACT;
  });
  const [questionsSequence, setQuestionsSequence] = useState([
    QUESTION_IDS.WHY_TRANSACT,
  ]);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState(() => {
    return [];
  });


  function handleBackClick() {
    const lastQuestionId =
      questionsAndAnswers[questionsAndAnswers.length - 1].questionId;
    const removeLastItem = questionsSequence.slice(0, -1);
    setQuestionsSequence(removeLastItem);
  }

  function handleUpdateAnswers({ questionId, answer, nextQuestion }) {
    const questionData = getQuestionData(questionId);

    const hasAnswers = questionsAndAnswers.find(
      (question) => question.questionId === questionId
    );

    if (!hasAnswers) {
      const newData = [
        ...questionsAndAnswers,
        { questionId, answers: [answer] },
      ];

      setQuestionsAndAnswers(newData);
      store(LOCAL_KEYS.FL_ANSWERS, newData);
      return;
    }

    const answerExists = hasAnswers.answers.includes(answer);
    if (answerExists) {
      const otherItems = questionsAndAnswers.filter(
        (question) => question.questionId !== questionId
      );
      const newAnswers = hasAnswers.answers.filter(
        (answer) => answer !== answer
      );

      const filtered = hasAnswers.answers.filter((a) => a !== answer);
      const newData = [
        ...otherItems,
        { questionId: hasAnswers.questionId, answers: filtered },
      ];
      setQuestionsAndAnswers(newData);
      store(LOCAL_KEYS.FL_ANSWERS, newData);
      return;
    }
    const otherItems = questionsAndAnswers.filter(
      (question) => question.questionId !== questionId
    );

    const newData = [
      ...otherItems,
      { questionId, answers: [...hasAnswers.answers, answer] },
    ];
    setQuestionsAndAnswers(newData);
    store(LOCAL_KEYS.FL_ANSWERS, newData);
    return;
  }

  function handleNextClick({ questionId, answer, nextQuestion }) {
    const uniqueAnswers = questionsAndAnswers.filter(
      (question) => question.questionId !== questionId
    );
    const newData = [...uniqueAnswers, { questionId, answers: [answer] }];
    setQuestionsAndAnswers(newData);
  }

  function deleteFormAnswers() {
    store.remove(LOCAL_KEYS.FL_ANSWERS);
    setQuestionsAndAnswers([]);
    setQuestionsSequence([QUESTION_IDS.WHY_TRANSACT]);
  }
  function getQuestionData(questionId) {
    return QUESTIONS.find((question) => question.id === questionId);
  }

  function getCurrentQuestionData() {
    return QUESTIONS.find(
      (question) =>
        question.id === questionsSequence[questionsSequence.length - 1]
    );
  }

  function handleAddons({ questionId, answer, nextQuestion }) {
    const hasAnswers = questionsAndAnswers.find(
      (question) => question.questionId === questionId
    );

    if (!hasAnswers) {
      const newData = [
        ...questionsAndAnswers,
        { questionId, answers: [answer] },
      ];

      setQuestionsAndAnswers(newData);
      store(LOCAL_KEYS.FL_ANSWERS, newData);
      return;
    }

    const removedItem = questionsAndAnswers.filter(
      (item) => item.questionId !== questionId
    );

    if (hasAnswers.answers.includes(answer)) {
      const removedAddon = hasAnswers.answers.filter((a) => a !== answer);
      const newData = [
        ...removedItem,
        { questionId, answers: [...removedAddon] },
      ];
      setQuestionsAndAnswers(newData);
      store(LOCAL_KEYS.FL_ANSWERS, {
        questionId: hasAnswers.questionId,
        answers: [newData],
      });

      return;
    }

    if (hasAnswers.answers.length === 2) {
      return;
    }

    const newData = [
      ...removedItem,
      { questionId, answers: [...hasAnswers.answers, answer] },
    ];
    setQuestionsAndAnswers(newData);
    store(LOCAL_KEYS.FL_ANSWERS, newData);
  }

  function getUserAnswers() {
    return questionsAndAnswers.map((questionAndAnswer) => {
      const question = QUESTIONS.find(
        ({ id }) => id === questionAndAnswer.questionId
      );
      const answers = question.answers;

      return {
        id: question.id,
        question: question.question,
        questionNumber: question.questionNumber,
        question,
        answers,
      };
    });
  }

  return (
    <OfferTypeContext.Provider
      value={{
        deleteFormAnswers,
        activeQuestion,
        handleBackClick,
        questionsSequence,
        setQuestionsSequence,
        handleAddons,
        getCurrentQuestionData,
        setActiveQuestion,
        handleNextClick,
        questionsAndAnswers,
        getUserAnswers,
        handleUpdateAnswers,
      }}
    >
      {children}
    </OfferTypeContext.Provider>
  );
}

export { OfferTypeContext, OfferTypeProvider };
