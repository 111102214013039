import React from "react";
import PropTypes from "prop-types";

const ArrowRight = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 16 14">
      <path d="M8.951.26l6.452 6.45-6.452 6.452-1.06-1.06L12.53 7.46H.803v-1.5h11.729L7.89 1.32 8.95.26z" />
    </svg>
  );
};

ArrowRight.propTypes = {
  className: PropTypes.string,
};

export default ArrowRight;
