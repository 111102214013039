import { css } from "styled-components";

const googleMapsCSS = () => css`
  /*
    Google API address dropdown styles
    .pac-container is dynamically appended and requires global classes
  */

  .pac-container {
    background-color: #FFFFFF;
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.color.primary50};
    box-shadow: none;
    margin-top: 8px;
    min-width: 325px;
    padding: 0 0 40px;
    z-index: 1051 !important;
    @media (--viewport-md) {
      min-width: 400px;
    }
  }

  .pac-logo {
    &::after {
      bottom: 11px !important;
    }
  }

  .pac-item {
    align-items: center;
    border-top: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.primary100};
    color: ${({ theme }) => theme.color.interface800};
    cursor: pointer;
    display: flex;
    font-family: 'Graphik Web', sans-serif;
    font-size: 14px;
    line-height: 1.2;
    padding: 15px 20px;
    text-align: left;

    &:hover {
      background-color: ${({ theme }) => theme.color.primary100};
    }
  }

  .pac-icon {
    /* background-image: url(../assets/img/pin.svg) !important; */
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 18px;
    margin-right: 20px;
    margin-top: 2px;
    vertical-align: top;
    width: 13.5px;
  }

  .pac-icon-marker {
    background-position: 0 0;
  }

  .pac-item-query {
    color: inherit;
    font-size: inherit;
    padding-right: 0;

    &:after {
      content: '\00a0';
    }
  }

  .pac-logo {
    &::after {
      bottom: 25px;
      position: absolute;
      right: 30px;
      width: 50%;
    }
  }
`;

export default googleMapsCSS;
