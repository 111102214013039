import styled from 'styled-components';
import { Title } from '../../shared/Typography/Title';
import { ButtonPrimary } from '../../shared/Buttons/Buttons';

export const ContactFormWrap = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom:48px;
  .type-of-contact-wrapper {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px;
    .type-of-contact-bar {
      height: 2px;
      width: 100%;
      width: 100%;
      background: ${({ theme }) => theme.color.interface200};
    }

    .type-of-contact-title {
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.interface600};
    }
  }

  .type-of-contact-button-area { 
    margin-top:16px;
  }

  ${Title} {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  ${ButtonPrimary} {
    width: 100%;
    max-width: 600px;
    margin-bottom: 16px;
  }

  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;
