import React, { useEffect, useRef } from 'react';
import loadGoogleMaps from 'src/utils/loadGoogleMaps';
import { Loader } from 'google-maps';
import { Container } from '../../shared/Container/Container';
import { Title } from '../../shared/Typography/Title';
import { ButtonPrimary, ButtonSecondary } from '../../shared/Buttons/Buttons';
import { EditAddressMapWrapper } from './property-relationship.style';
import GoogleMapsIcon from '../../../assets/images/icons/google-maps-pin.png';
function EditAddress({
  address,
  lat,
  lng,
  onDontEditAddressClick,
  onFixAddressClick,
}) {
  const mapRef = useRef();

  useEffect(() => {
    const isGoogleMapsAddress = isValidLatLng();

    if (isGoogleMapsAddress) {
      initGoogleMaps();
    }
  }, []);

  async function initGoogleMaps() {
    const loader = new Loader(`AIzaSyB7oTxyvt1C06qocu_2glSGK2rYjmxp5dM`);
    google = await loader.load();
    const map = new google.maps.Map(mapRef.current, {
      center: { lat, lng },
      zoom: 15,
      zoomControl: true,
      draggable: false,
      scrollwheel: false,
      fullscreenControl: false,
      streetViewControl: false,
      disableDoubleClickZoom: true,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
    });

    /* eslint-disable-next-line */
    const marker = new google.maps.Marker({
      position: { lat, lng },
      map,
      icon: GoogleMapsIcon,
    });

    marker.setMap(map);
  }

  function isValidLatLng() {
    const isValidLat = lat > -90 && lat < 90;
    const isValidLng = lng > -180 && lng < 180;
    return isValidLat && isValidLng;
  }

  const isGoogleMapsAddress = isValidLatLng();
  return (
    <EditAddressMapWrapper>
      <Container>
        {isGoogleMapsAddress && (
          <div className="edit-address-map" ref={mapRef}></div>
        )}
        <div className="edit-address-text">
          <Title size="small">{address}</Title>
          <p>Is this the correct address?</p>
        </div>
        <div className="edit-address-buttons">
          <ButtonSecondary onClick={onDontEditAddressClick}>
            Close
          </ButtonSecondary>
          <ButtonPrimary onClick={onFixAddressClick}>Fix address</ButtonPrimary>
        </div>
      </Container>
    </EditAddressMapWrapper>
  );
}

export default EditAddress;
