import React, { useEffect, useState } from 'react';
import { TAddon, TOffer } from '../OfferWishlist/types/offer-wishlist';
import store from 'store2';
import LOCAL_KEYS from '../../constants/localStorage';
import { useStaticQuery, graphql } from 'gatsby';
import { cartVersionId } from './shopping-cart.constants';
import { ShoppingCart } from './shopping-cart.types';

interface Props {
  children: React.ReactNode;
}

const defaultValues: TShoppingCartValues = {
  offerTypes: [],
  updateOfferTypes: () => {},
  addons: [],
  updateAddons: () => {},
  isAddonsError: false,
  isOfferTypesError: false,
  setIsAddonsError: () => {},
  setIsOfferTypesError: () => {},
  isCartOpen: false,
  setIsCartOpen: () => {},
  deleteCart: () => {},
};

export type TShoppingCartValues = {
  offerTypes: string[];
  updateOfferTypes: (offerTypeIds: string[]) => void;
  addons: string[];
  updateAddons: (addons: string[]) => void;
  isOfferTypesError: boolean;
  isAddonsError: boolean;
  setIsOfferTypesError: (isError: boolean) => void;
  setIsAddonsError: (isError: boolean) => void;
  deleteCart: () => void;
  isCartOpen: boolean;
  setIsCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShoppingCartContext = React.createContext<TShoppingCartValues>({
  ...defaultValues,
});

function ShoppingCartProvider(props: Props) {
  const { children } = props;
  const data = useStaticQuery(graphql`
    query {
      allAddons: allContentfulAddOnCard(filter: { title: { ne: "test" } }) {
        nodes {
          isDefaultSelectedCart
          contentful_id
        }
      }
    }
  `);

  const [offerTypes, setOfferTypes] = useState<string[]>(() => {
    const cart = store.get(LOCAL_KEYS.SHOPPING_CART);
    return cart?.offerTypes ?? [];
  });
  const [isOfferTypesError, setIsOfferTypesError] = useState(false);

  const [addons, setAddons] = useState<string[]>(() => {
    const cart = store.get(LOCAL_KEYS.SHOPPING_CART) as ShoppingCart;

    if (cart?.versionId !== cartVersionId) {
      return [];
    }

    return cart?.addons ?? [];
  });
  const [isAddonsError, setIsAddonsError] = useState(false);

  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    if (!store.get(LOCAL_KEYS.SHOPPING_CART)) {
      store(LOCAL_KEYS.SHOPPING_CART, {
        offerTypes: [],
        addons: [],
        versionId: cartVersionId,
      });
    }
  }, []);

  function updateOfferTypes(newOfferTypes: string[]) {
    if (newOfferTypes.length > 1) {
      setIsOfferTypesError(true);
      return;
    }

    store(LOCAL_KEYS.SHOPPING_CART, {
      offerTypes: newOfferTypes,
      addons,
      versionId: cartVersionId,
    });
    setOfferTypes(newOfferTypes);
  }

  function deleteCart() {
    store.remove(LOCAL_KEYS.SHOPPING_CART);
    setOfferTypes([]);
    setAddons([]);
  }

  function updateAddons(newAddons: string[]) {
    if (newAddons.length > 3) {
      setIsAddonsError(true);
      return;
    }

    store(LOCAL_KEYS.SHOPPING_CART, {
      offerTypes,
      addons: newAddons,
      versionId: cartVersionId,
    });
    setAddons(newAddons);
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        offerTypes,
        updateOfferTypes,
        addons,
        isAddonsError,
        setIsAddonsError,
        isOfferTypesError,
        setIsOfferTypesError,
        updateAddons,
        isCartOpen,
        deleteCart,
        setIsCartOpen,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}

export { ShoppingCartContext, ShoppingCartProvider };
