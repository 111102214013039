import React from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from '../Buttons/Buttons';
import styled from 'styled-components';
// @ts-ignore
import { Container } from '../Container/Container';
// @ts-ignore
import ChevronThick from '../../icons/ChevronThick/index';
// @ts-ignore
import ArrowRight from '../../icons/ArrowRight';

type StyledProps = {
  breakpoint: number;
};

const FixedButtonArea = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: ${({ theme }) => theme.layout.boxShadow};
`;

const ButtonNavigationSafeSpace = styled.div<StyledProps>`
  height: 120px;
  width: 100%;
  display: block;
  @media (min-width: ${({ breakpoint }) => `${breakpoint}px`}) {
    height: 0px;
    display: none;
  }
`;
const ButtonNavigationWrap = styled.div<{ breakpoint: number }>`
  display: flex;
  position: fixed;
  bottom: 0;
  background: White;
  height: 80px;
  align-items: center;
  left: 0;
  width: 100%;
  box-shadow: ${({ theme }) => theme.layout.boxShadow};
  z-index: 699;
  .chevron-back {
    display: none;
  }
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  ${ButtonPrimary} {
    width: 100%;
  }

  svg {
    transform: rotate(-90deg);
  }
  @media (min-width: ${({ breakpoint }) => `${breakpoint}px`}) {
    position: static;
    box-shadow: none;
    height: 100%;
    ${ButtonPrimary} {
      max-width: 320px;
      margin: 0 auto;
    }
    ${ButtonTertiary} {
      text-decoration: underline;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      left: 24px;
      top: 40px;
      color: ${({ theme }) => theme.color.primary600};
      text-decoration: None;
      z-index: 49;

      &:hover {
        color: ${({ theme }) => theme.color.primary700};
        text-decoration: underline;
      }
      svg {
        width: 16px;
        height: 16px;
        fill: ${({ theme }) => theme.color.primary500};
        rotate: -90deg;
      }
    }

    .chevron-back {
      display: block;
    }
  }

  .button-navigation-button-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    ${ButtonPrimary} {
      max-width: 275px;
    }
  }
`;
interface Props {
  children: React.ReactNode;
  breakpoint?: number;
}

function ButtonNavigation({ children, breakpoint = 768 }: Props) {
  return (
    <>
      <ButtonNavigationWrap breakpoint={breakpoint}>
        <Container>{children}</Container>
      </ButtonNavigationWrap>
      <ButtonNavigationSafeSpace
        breakpoint={breakpoint}
        className="button-navigation-safe-space"
      ></ButtonNavigationSafeSpace>
    </>
  );
}

interface NextButtonProps {
  buttonType?: 'submit' | 'button';
  buttonText?: string | React.ReactNode;
  isNextStepDisabled?: boolean;
  onNextClick?: () => void;
  shouldRemoveDisabledStyles?: boolean;
}

function NextButton({
  buttonType,
  buttonText = 'Next',
  isNextStepDisabled,
  onNextClick,
  shouldRemoveDisabledStyles = false,
}: NextButtonProps) {
  return (
    <ButtonPrimary
      shouldRemoveDisabledStyles={shouldRemoveDisabledStyles}
      type={buttonType}
      disabled={isNextStepDisabled}
      onClick={onNextClick}
    >
      {buttonText}
    </ButtonPrimary>
  );
}

interface BackButtonProps {
  onBackClick: () => void;
  buttonText?: string | React.ReactNode;
  isDisabled?: boolean;
}

function BackButton({
  onBackClick,
  buttonText = 'Back',
  isDisabled = false,
}: BackButtonProps) {
  return (
    <ButtonTertiary
      disabled={isDisabled}
      className="button-back"
      onClick={onBackClick}
    >
      <ArrowRight className="chevron-back" /> {buttonText}
    </ButtonTertiary>
  );
}

ButtonNavigation.NextButton = NextButton;
ButtonNavigation.BackButton = BackButton;

export default ButtonNavigation;
