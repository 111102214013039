const zIndexBase = {
  hidden: -1,
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
  6: 600,
  7: 700,
  8: 800,
  9: 900,
  10: 1000,
};

const zIndex = {
  flowBG: zIndexBase.hidden,
  aboutHeroTitle: zIndexBase['1'],
  allOfferTypeSection: zIndexBase['1'],
  blogPostRelated: zIndexBase['1'],
  goalConsiderations: zIndexBase['1'],
  loveYourOfferTimelineTitle: zIndexBase['1'],
  navAddressBar: zIndexBase['1'],
  offer: zIndexBase['1'],
  tableOfContents: zIndexBase['1'],
  quoteFlowBackBtn: zIndexBase['1'],
  storiesHero: zIndexBase['1'],
  homeHeroContent: zIndexBase['2'],
  confetti: zIndexBase['6'],
  continueOffer: zIndexBase['6'],
  intro: zIndexBase['6'],
  progressBar: zIndexBase['6'],
  offerTypeComparison: zIndexBase['6'],
  offerComparisonSelector: zIndexBase['6'],
  loadingScreen: zIndexBase['7'],
  stickyCTABar: zIndexBase['6'],
  comparisonTray: zIndexBase['7'],
  nav: zIndexBase['7'],
  navMenu: zIndexBase['8'],
  modal: zIndexBase['10'],
  toast: zIndexBase['10'],
  addressSearch: zIndexBase['-1'],
  
  shoppingCart: zIndexBase['25'],
  wishlistSummaryButton: zIndexBase['10'],
};

export default zIndex;
