import styled, { css } from 'styled-components';

interface Props {
  isError?: boolean;
  isSuccess?: boolean;
  size?: 'large' | 'small';
  placeholder?: string;
  value?: string;
  pattern?: string;
  type?: string;
}

export const Input = styled.input<Props>`
  height: ${({ theme, size }) =>
    size === 'large'
      ? theme.layout.spacing.formElementBaseHeightLarge
      : theme.layout.spacing.formElementBaseHeight};
  width: 100%;
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  padding: 0 12px;
  display: block;
  ${({ isError, isSuccess, theme }) => {
    if (isError) {
      return css`
        border: 2px solid ${({ theme }) => theme.color.utilityError};
      `;
    }

    if (isSuccess) {
      return css`
        border: 2px solid ${({ theme }) => theme.color.primary500};
      `;
    }

    return css`
      border: 2px solid ${theme.color.interface200};
    `;
  }}
  ${({ theme, isError }) =>
    isError ? theme.color.utilityError : theme.color.interface200};
  font-size: 16px;
  color: ${({ theme }) => theme.color.interface800};
`;

export const InputControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const InputError = styled.div`
  color: ${({ theme }) => theme.color.utilityError};
  font-size: 14px;
  font-weight: 500;
`;

export const InputSucces = styled.div`
  color: ${({ theme }) => theme.color.primary500};
  font-size: 14px;
  font-weight: 500;
`;
