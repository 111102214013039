export const LINKS = {
  COMPARE: {
    id: 'compare',
    label: 'Compare',
    url: '/compare/',
  },
  OFFER_TYPES: {
    id: 'offer-types',
    label: 'Offer Types',
    url: '/offer-types/',
  },
  ADD_ONS: {
    id: 'add-ons',
    label: 'Add-ons',
    url: '/add-ons/',
  },
  HOW_IT_WORKS: {
    id: 'how-it-works',
    label: 'How It Works',
    url: '/how-it-works/',
  },
  PRICING: {
    id: 'pricing',
    label: 'Pricing',
    url: '/pricing/',
  },
  ABOUT: {
    id: 'about',
    label: 'About',
    url: '/about/',
  },
  FAQ: {
    id: 'faq',
    label: 'FAQ',
    url: '/faq/',
  },
  AGENTS: {
    id: 'agents',
    label: 'Agents',
    url: '/agents/',
  },
  PARTNERS: {
    id: 'partners',
    label: 'Partners',
    url: '/partners/',
  },
  RESOURCES: {
    id: 'resources',
    label: 'Resources',
    url: 'https://resources.flexible.us',
  },
  OFFER_BUILDER: {
    id: 'take-the-quiz',
    label: 'Offer Type Finder<sup>®</sup>',
    url: '/finder/',
  },
  OFFER_BUILDER_ADDRESS: {
    id: 'offer-builder-address',
    label: 'Offer builder address',
    url: '/offer-builder/address/',
  },
  OFFER_BUILDER_ADDRESS_CONFIRMATION: {
    id: 'offer-builder-address-confirmation',
    label: 'Offer builder address confirmation',
    url: '/offer-builder/address-confirmation/',
  },
  OFFER_BUILDER_OFFER: {
    id: 'offer-builder-offer',
    label: 'Offer builder offer flow',
    url: '/offer-builder/offer/',
  },
  GET_A_QUOTE_ADDRESS: {
    id: 'get-a-quote-address',
    label: 'Get a quote address',
    url: '/finder/address/',
  },
  GET_A_QUOTE: {
    id: 'get-a-quote',
    label: 'Get a quote',
    url: '/finder/',
  },
  GET_A_QUOTE_DONE: {
    id: 'get-a-quote-done',
    label: 'Get a quote',
    url: '/finder/done/',
  },
  TERMS_OF_SERVICE: {
    id: 'terms-of-service',
    label: 'Terms of service',
    url: '/terms-of-service/',
  },
  PRIVACY: {
    id: 'privacy',
    label: 'Privacy policy',
    url: '/privacy/',
  },
  ALL_GOALS: {
    id: 'goals',
    label: 'Goals',
    url: '/goals/',
  },
  ALL_BLOG_POSTS: {
    id: 'blog',
    label: 'Blog',
    url: '/blog/',
  },
  ALL_STORIES: {
    id: 'stories',
    label: 'Stories',
    url: '/stories/',
  },
  ALL_SITUATIONS: {
    id: 'situations',
    label: 'Situations',
    url: '/situations/',
  },
  GUIDE: {
    id: 'guide',
    label: 'Guide',
    url: '/guide/',
  },
  GET_FREE_OFFER: {
    id: 'get-free-offer',
    label: 'Get Free Offer',
    url: '/get-offer/',
  },
  GET_FREE_OFFER_CONTINUE: {
    id: 'get-free-offer-continue',
    label: 'Get Free Offer',
    url: '/get-offer-continue/',
  },
  GET_FREE_OFFER_ADDRESS_INPUT: {
    id: 'get-free-offer-address-input',
    label: 'Get Free Offer',
    url: '/get-offer/address/',
  },

  GREAT_DEAL_FRAMEWORK_PAGE: {
    id: 'great-deal-framework-page',
    label: 'Great Deal Framework',
    url: '/courses/greatdealframework/',
  },

  GREAT_DEAL_FRAMEWORK_COURSE_PAGE: {
    id: 'great-deal-framework-course-page',
    label: 'Great Deal Framework Course',
    url: '/courses/greatdealframework/sections/',
  },

  DEAL_STUDIO: {
    id: 'deal-studio-page',
    label: 'Deal Studio',
    url: '/deal-studio',
  },

  DEAL_LOUNGE: {
    id: 'deal-lounge-page',
    label: 'Deal Lounge',
    url: '/lounge',
  },
  SIGN_UP: {
    id: 'sign-up',
    label: 'Sign Up',
    url: '/sign-up',
  },
  SIGN_IN: {
    id: 'sign-in',
    label: 'Sign In',
    url: '/sign-in',
  },
  ACCOUNT: {
    id: 'account',
    label: 'Account',
    url: '/account',
  },

  WISHLIST_ACCESS_EXISTING: {
    id: 'wishlist-access-existing',
    label: 'Wishlist Dashboard',
    url: '/wishlist/dashboard/',
  },
  WISHLIST_CREATE_NEW: {
    id: 'wishlist-create-wishlist',
    label: 'Wishlist',
    url: '/wishlist/',
  },
  DEAL_CRITERIA: {
    id: 'deal-criteria',
    label: 'Deal Criteria',
    url: '/deal-criteria/',
  },
};

export const NAV_LINKS = [
  LINKS.GET_FREE_OFFER_ADDRESS_INPUT,
  LINKS.OFFER_BUILDER,
  LINKS.DEAL_LOUNGE,
  LINKS.ABOUT,
];

export const FOOTER_LINKS = [
  [LINKS.GET_FREE_OFFER_ADDRESS_INPUT, LINKS.WISHLIST_CREATE_NEW],
  [LINKS.DEAL_LOUNGE, LINKS.DEAL_STUDIO],
  [LINKS.OFFER_BUILDER, LINKS.OFFER_TYPES],
  [LINKS.ABOUT, LINKS.ADD_ONS],
  [LINKS.PRICING, LINKS.ALL_STORIES],
  [LINKS.AGENTS, LINKS.ALL_SITUATIONS],
  [LINKS.PARTNERS, LINKS.FAQ],
  [LINKS.ALL_GOALS,],
  // LINKS.ALL_BLOG_POSTS,
];

export const FOOTER_LINKS_DESKTOP = [
  LINKS.GET_FREE_OFFER_ADDRESS_INPUT, // 1
  LINKS.OFFER_TYPES,
  LINKS.AGENTS,
  LINKS.OFFER_BUILDER, // 1
  LINKS.ADD_ONS,
  LINKS.PARTNERS,
  LINKS.DEAL_STUDIO, // 1
  LINKS.ALL_STORIES,
  LINKS.ALL_GOALS,
  LINKS.ABOUT, // 1
  LINKS.ALL_GOALS,
  LINKS.FAQ,
  LINKS.PRICING, // 2
  LINKS.ALL_SITUATIONS,
];

export const OTHER_LINKS = [
  LINKS.WISHLIST_CREATE_NEW,
  LINKS.OFFER_TYPES,
  // LINKS.GUIDE,
  LINKS.ADD_ONS,
  LINKS.ALL_STORIES,
  // LINKS.ALL_SITUATIONS,
  // LINKS.FAQ,

  // LINKS.AGENTS,
  // LINKS.PARTNERS,
  // LINKS.ALL_BLOG_POSTS,
];
