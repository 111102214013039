import React, { useState, useEffect } from 'react';
import ButtonNavigation from 'src/components/shared/ButtonNavigation/ButtonNavigation';
import styled from 'styled-components';
import ProgressBar from '../ProgressBar/ProgressBar';
import ContactForm from './ContactForm/ContactForm';
import FreeOfferQuestionaire from './FreeOfferQuestionaire/FreeOfferQuestionaire';
import PropertyRelationship from './PropertyRelationShip/PropertyRelationship';
import UpdateAddress from './UpdateAddress/UpdateAddress';
import { PageWrapper } from './get-free-offer.styles';
import store from 'store2';
import LOCAL_KEYS from '../../constants/localStorage';

const ADDRESS_TYPE_STATUS = {
  GOOGLE_MAPS: 'GOOGLE_MAPS',
  MANUAL: 'MANUAL',
  IS_MANUALLY_ENTERING: 'IS_MANUALLY_ENTERING',
};

export const PROPERTY_RELATIONSHIP_ANSWERS = {
  owner: 'Owner',
  broker: 'Broker/Agent',
  other: 'Other',
};

const PROPERTY_CONDITION_ANSWERS = [
  'Like new (major renovation in last 5 years)',
  'Good (well-maintained)',
  'Below average (needs major repairs)',
  'Poor or land (Major renovation required or new development)',
];

const HOW_SOON_UNTIL_SALE_ANSWERS = [
  'As soon as possible',
  '1-3 Months',
  '4+ Months',
  'Not sure, exploring options',
];

const TOTAL_FORM_STEPS = 5;

const PageWrap = styled.main`
  min-height: -webkit-fill-available;
  height: calc(
    100vh - 10px - 80px - ${({ theme }) => theme.layout.spacing.navbarHeight}
  );
`;

function GetFreeOfferContainer({ context }) {
  const {
    addressData,
    didManuallyEnterAddress,
    questionAnswers,
    formProgress,
  } = context.state;
  const {
    onSetAddressData,
    onOfferSubmissionComplete,
    onDidManuallyEnterAddress,
    onUpdateQuestionAnswers,
    onFormProgressChange,
  } = context.actions;

  const {
    specificRelationship,
    propertyCondition,
    transactionDuration,
    propertyRelationship,
  } = questionAnswers;

  const [shouldDisplayManualAddressModal, setShouldDisplayManualAddressModal] =
    useState(false);

  useEffect(() => {
    function handleUnload() {
      store(LOCAL_KEYS.IS_CHECKOUT, false);
    }

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  function renderFormStep() {
    if (formProgress === 1) {
      const isValidAddress =
        addressData.hasOwnProperty('address') && addressData.address.length > 0;
      if (!isValidAddress) {
        return (
          <>
            <div style={{ height: 80 }}></div>
            <UpdateAddress
              onUpdateAddressSuccess={(addressData) => {
                onSetAddressData(addressData);
              }}
              shouldDisplayCancelButton={false}
            />
          </>
        );
      }

      return (
        <div>
          {shouldDisplayManualAddressModal ? (
            <div>
              <UpdateAddress
                onUpdateAddressSuccess={(addressData) => {
                  setShouldDisplayManualAddressModal(false);
                  onSetAddressData(addressData);
                  onDidManuallyEnterAddress();
                  onFormProgressChange(formProgress + 1);
                }}
                onCancelClick={() => setShouldDisplayManualAddressModal(false)}
              />
            </div>
          ) : (
            <div>
              <PropertyRelationship
                onInputEnterClick={() => onFormProgressChange(formProgress + 1)}
                address={addressData.address}
                addressLat={addressData.lat}
                addressLng={addressData.lng}
                onSpecificRelationshipChange={(value) => {
                  onUpdateQuestionAnswers({
                    specificRelationship: value,
                  });
                }}
                onFixAddressClick={() =>
                  setShouldDisplayManualAddressModal(true)
                }
                specificRelationship={specificRelationship}
                propertyRelationship={propertyRelationship}
                onPropertyRelationshipChange={(value) => {
                  onUpdateQuestionAnswers({
                    propertyRelationship: value,
                  });
                }}
              />
              <ButtonNavigation>
                <ButtonNavigation.NextButton
                  isNextStepDisabled={
                    (propertyRelationship ===
                      PROPERTY_RELATIONSHIP_ANSWERS.other &&
                      specificRelationship.length < 3) ||
                    !propertyRelationship
                  }
                  onNextClick={() => onFormProgressChange(formProgress + 1)}
                />
              </ButtonNavigation>
            </div>
          )}
        </div>
      );
    }

    if (formProgress === 2) {
      return (
        <div>
          <FreeOfferQuestionaire
            onChange={(value) => {
              onUpdateQuestionAnswers({
                propertyCondition: value,
              });
            }}
            value={propertyCondition}
            answers={PROPERTY_CONDITION_ANSWERS}
            question="What is the condition of the property?"
          />

          <ButtonNavigation>
            <ButtonNavigation.BackButton
              onBackClick={() => onFormProgressChange(formProgress - 1)}
            />
            <ButtonNavigation.NextButton
              isNextStepDisabled={!propertyCondition}
              onNextClick={() => {
                onFormProgressChange(formProgress + 1);
              }}
            />
          </ButtonNavigation>
        </div>
      );
    }

    if (formProgress === 3) {
      return (
        <div>
          <FreeOfferQuestionaire
            onChange={(value) => {
              onUpdateQuestionAnswers({
                transactionDuration: value,
              });
            }}
            answers={HOW_SOON_UNTIL_SALE_ANSWERS}
            question="How soon do you want to transact?"
            value={transactionDuration}
          />
          <ButtonNavigation>
            <ButtonNavigation.BackButton
              buttonType="button"
              onBackClick={() => onFormProgressChange(formProgress - 1)}
            />
            <ButtonNavigation.NextButton
              buttonType="button"
              isNextStepDisabled={!transactionDuration}
              onNextClick={() => onFormProgressChange(formProgress + 1)}
            />
          </ButtonNavigation>
        </div>
      );
    }

    if (formProgress === 4) {
      return (
        <div>
          <ContactForm
            propertyRelationship={propertyRelationship}
            specificRelationship={specificRelationship}
            propertyCondition={propertyCondition}
            onSubmitFormSuccess={() => onOfferSubmissionComplete()}
            transactionDuration={transactionDuration}
            zip={addressData.zip}
            state={addressData.state}
            city={addressData.city}
            street={addressData.street}
          />
          <ButtonNavigation>
            <ButtonNavigation.BackButton
              onBackClick={() => onFormProgressChange(formProgress - 1)}
            />
          </ButtonNavigation>
        </div>
      );
    }
  }

  return (
    <>
      <ProgressBar
        currentStepNumber={formProgress}
        stepTotal={TOTAL_FORM_STEPS}
      />

      <PageWrapper>{renderFormStep()}</PageWrapper>
    </>
  );
}

export default GetFreeOfferContainer;
