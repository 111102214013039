const sidePadding = {
  xsm: "25px",
  sm: "60px",
  md: "80px",
  lg: "110px",
  xlg: "150px",
};

const spacing = {
  spacing01: "0.5rem",
  spacing02: "0.75rem",
  spacing03: "1rem",
  spacing04: "1.25rem",
  spacing05: "1.50rem",
  spacing06: "1.75rem",
  spacing07: "2rem",
  spacing08: "2.25rem",
  spacing09: "2.5rem",
  spacing10: "2.75rem",
  spacing11: "3rem",
  spacing12: "3.25rem",
  spacing13: "3.5rem",
  spacing14: "3.75rem",
  spacing15: "4rem",
  spacing16: "4.25rem",
  spacing17: "4.5rem",
  spacing18: "4.75rem",
  spacing19: "5rem",
  spacing20: "5.25rem",
  spacing21: "5.5rem",
  spacing22: "6rem",
  spacing23: "6.5rem",
  spacing24: "7rem",

  navbarHeight: "80px",
  navbarHeightLarge: "100px",
  courseDetailFixedHeaderHeight: "88px",
  formElementBaseHeight: "3.5rem",
  formElementBaseHeightLarge: "4.5rem",
};

const borderRadius = "4px";

const boxShadow = "0 4px 14px 2px rgba(0, 0, 0, 0.1)";

const maxWidth = "1920px";

export default {
  boxShadow,
  maxWidth,
  sidePadding,
  spacing,
  borderRadius,
};
