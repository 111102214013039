import styled, { keyframes } from 'styled-components';

const animateProgress = (previousPosition, currentPosition) => keyframes`
0% {
  transform : translateX(${previousPosition}%) 
}
100% {
  height: 100px;
  background:red;
  transform : translateX(${currentPosition}%)
}
`;

export const BarOuter = styled.div`
  background-color: ${({ theme }) => theme.color.interface100};
  height: 8px;
  position: fixed;
  width:100%;
  z-index: ${({theme}) => theme.zIndex.progressBar} ; 
  .progress-bar-progress-percent {
    color: white;
    position: absolute;
    padding: 5px 10px;
    background: ${({ theme }) => theme.color.primary500};
    font-size: 12px;
    border-radius: ${({ theme }) => theme.layout.borderRadius};
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px 0px;
    top: 16px;
    left: ${({ barWidth }) => barWidth}%;
    transform: translateX(-50%);
    right: initial;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:84px;
    .progress-bar-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 8px 4px;
      border-radius: 1px;
      border-color: transparent transparent
        ${({ theme }) => theme.color.primary500} transparent;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const BarInner = styled.div`
  background-color: ${({ theme }) => theme.color.primary500};
  height: 100%;
  transition: width 200ms ease;
  width: ${(props) => props.barWidth}%;
  border-radius: 0 ${({ theme }) => theme.layout.borderRadius}
    ${({ theme }) => theme.layout.borderRadius} 0;
  /*   animation: ${(previousWidth, barWidth) =>
    animateProgress(previousWidth, barWidth)} 0.5s linear;
 */
`;
