import React from 'react';
const QUESTION_IDS = {
  WHY_TRANSACT: 'WHY_TRANSACT',
  WHICH_TRANSACTION_TYPE: 'WHICH_TRANSACTION_TYPE',
  DO_ANY_OF_THESE_APPLY: 'DO_ANY_OF_THESE_APPLY',
  WHATS_YOUR_PRIMARY_GOAL: '  WHATS_YOUR_PRIMARY_GOAL',
  OPEN_TO_BEING_PAID_OVERTIME: 'OPEN_TO_BEING_PAID_OVERTIME',
  DO_YOU_PLAN_ON_EXCHANGE: 'DO_YOU_PLAN_ON_EXCHANGE',
  INVESTMENT_RISK_APPETITE: 'INVESTMENT_RISK_APPETITE',
  TRANSACTION_BETTER: 'TRANSACTION_BETTER',
  FLOW_DONE: 'FLOW_DONE',
};

const OFFER_TYPES = {
  EASY_SALE: '2yeuAJGyrCtlZc5KIYR7CB',
  INSTALLMENT_SALE: '7vJV5PlvLIYJKl2NKAGqLz',
  GROUND_OR_MASTER_LEASE: '2zrEeilewAZ5eUa1AQoTDx',
  PARTNERSHIP: '47C5JZgn6mZZIZ9fbrPt4c',
  SALE_LEASEBACK: '2xxsJ8G7CWVYukMwxMrSeR',
  BUILDER_PRESALE: '6XNu9b4c6w0J1zIJ1FaIoK',
  CASH_FOR_EQUITY: 'e5YjXLR4WgJP93Az6CdMJ',
  TRADE_IN: '1a1sdcm7lkG6Di68BnGjsH',
  BUSINESS_BUILDING_SALE: '1byZsSPQqhGLvoYS9GhfWS',
};

const ADDON_IDS = {
  FAST_NON_REFUNDABLE: '48BeQtpYVOP1tJ61Yj9CJH',
  CUSTOM_CLOSE_DATE: '5LrZZzakjZgQFu9D5juVP9',
  FAST_CLOSE: '4YJSjavOuchR74eKYF5Wlx',
  CUSTOM_CO_OWNER_OUTCOMES: '4eYtQEJ1LgN8FQ6B3MRsfO',
  LEASEBACK: '2tUspCxMLVehWKiezthZnk',
  CASH_NOW: '1MEpFeX0EMtpc1AFQkrt6v',
  LOAN_TAKEOVER: '7tHWs8o9dR5Xgims8Lsq1T',
  CANCEL_TRANSACTION: '7ulIgplbNz2koyMp5gw9E5',
  PRE_CLOSING_FLEXIBILITY: '6HRvr0EmcmrwxnjqiiIN6y',
  INVEST_WITH_FLEXIBLE: '2VqcOIF8DcvxOLkQf4NTH8',
  AS_IS: '4O1ii6563jdX6oJDjI6kig',
};

const FLOW_DONE = {
  id: QUESTION_IDS.FLOW_DONE,
  question: 'Flow Done',
};

const MAKE_YOUR_TRANSACTION_BETTER = {
  id: QUESTION_IDS.TRANSACTION_BETTER,
  question: 'What would make your transaction better?',
  agentQuestion: 'What would make the transaction better for the owner?',
  subTitle: 'Choose up to 2',
  questionNumber: 8,
  answers: [
    {
      answer: 'Custom close date',
      answeredQuestion: QUESTION_IDS.TRANSACTION_BETTER,
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.CUSTOM_CLOSE_DATE,
    },
    {
      answer: 'Quick close',
      answeredQuestion: QUESTION_IDS.TRANSACTION_BETTER,
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.FAST_CLOSE,
    },
    {
      answer: 'Fast closing certainty',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.FAST_NON_REFUNDABLE,
    },
    {
      answer: 'Custom terms for each co-owner',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.CUSTOM_CO_OWNER_OUTCOMES,
    },
    {
      answer: 'Transact and continue to occupy',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.LEASEBACK,
    },
    {
      answer: 'Cash now, before transaction closing',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.CASH_NOW,
    },
    {
      answer: 'Take over my existing loan',
      agentAnswer: 'Take over the existing loan',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.LOAN_TAKEOVER,
    },
    {
      answer: 'Option to cancel transaction',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.CANCEL_TRANSACTION,
    },
    {
      answer: 'Option to adjust transaction terms after offer acceptance',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.PRE_CLOSING_FLEXIBILITY,
    },
    {
      answer: 'Option to invest in real estate with Flexible',
      nextQuestion: QUESTION_IDS.FLOW_DONE,
      points: [],
      addonId: ADDON_IDS.INVEST_WITH_FLEXIBLE,
    },
  ],
};

const INVESTMENT_RISK_APPETITE = {
  id: QUESTION_IDS.INVESTMENT_RISK_APPETITE,
  question: 'Which best describes your investment risk appetite?',
  agentQuestion: `Which best describes the owner's investment risk appetite?`,
  agentSubtitle: `Certain transaction options include investment-like structures which increase earning potential. Your selection helps us remove options that aren't a good fit for the owner.`,
  subTitle:
    'Certain transaction options include investment-like structures which increase earning potential. Your selection helps us remove options that aren’t a good fit for you.',
  questionNumber: 7,
  answers: [
    {
      answer: 'Conservative',
      nextQuestion: QUESTION_IDS.TRANSACTION_BETTER,
      message: (
        <span>
          You're open to seeing options with <b>lower risk</b>.{' '}
        </span>
      ),
      agentMessage: (
        <span>
          The owner is open to seeing options with <b>lower risk</b>.
        </span>
      ),
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.GROUND_OR_MASTER_LEASE,
          points: 1,
        },
      ],
    },
    {
      answer: 'Moderate',
      nextQuestion: QUESTION_IDS.TRANSACTION_BETTER,
      message: (
        <span>
          You’re open to seeing options with <b>moderate risk</b>.
        </span>
      ),
      agentMessage: (
        <span>
          The owner's open to seeing options with <b>moderate risk</b>.
        </span>
      ),
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.PARTNERSHIP,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.GROUND_OR_MASTER_LEASE,
          points: 1,
        },
      ],
    },
    {
      answer: 'Aggressive',

      message: (
        <span>
          You’re open to <b>higher risk</b> for <b>higher potential reward</b>.
        </span>
      ),
      agentMessage: (
        <span>
          The owner's open to <b>higher risk</b> for{' '}
          <b>higher potential reward</b>.
        </span>
      ),
      nextQuestion: QUESTION_IDS.TRANSACTION_BETTER,
      points: [
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.PARTNERSHIP,
          points: 1,
        },
      ],
    },
  ],
};

const WHATS_YOUR_PRIMARY_GOAL = {
  id: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
  question: "What's your primary financial goal?",
  agentQuestion: `What's the owner's primary financial goal?`,
  questionNumber: 6,
  answers: [
    {
      answer: 'Preserve or grow wealth',
      nextQuestion: QUESTION_IDS.INVESTMENT_RISK_APPETITE,
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.GROUND_OR_MASTER_LEASE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.PARTNERSHIP,
          points: 1,
        },
      ],
    },
    {
      answer: 'Generate passive income',
      nextQuestion: QUESTION_IDS.INVESTMENT_RISK_APPETITE,
      message: (
        <span>
          You want <b>income</b>.
        </span>
      ),
      agentMessage: (
        <span>
          The owner wants <b>income</b>.
        </span>
      ),
      points: [
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.GROUND_OR_MASTER_LEASE,
          points: 1,
        },
      ],
    },
  ],
};

const DO_YOU_PLAN_ON_EXCHANGE = {
  id: QUESTION_IDS.DO_YOU_PLAN_ON_EXCHANGE,
  questionNumber: 5,
  question: 'Do you plan on doing a 1031 exchange? ',
  agentQuestion: `Does the owner plan on doing a 1031 exchange?`,
  subTitle:
    'A 1031 exchange is where an owner sells and buys another “like-kind” property to avoid paying taxes on the sale.',
  answers: [
    {
      answer: 'Yes',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      agentMessage: (
        <span>
          The owner wants to do a <b>1031 exchange</b>.
        </span>
      ),
      message: (
        <span>
          You want to do a <b>1031 exchange</b>.
        </span>
      ),
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 5,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
    {
      answer: 'No',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [],
    },
    {
      answer: 'Maybe',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
  ],
};

const OPEN_TO_BEING_PAID_OVERTIME = {
  id: QUESTION_IDS.OPEN_TO_BEING_PAID_OVERTIME,
  question: 'Are you open to getting paid over time? ',
  agentQuestion: 'Is the owner open to getting paid over time?',
  subTitle: `Choosing "yes" gives you transaction options that provide greater earning potential and income.`,
  agentSubtitle: `Choosing "yes" gives the owner transaction options that provide greater earning potential and income.`,
  questionNumber: 4,
  answers: [
    {
      answer: 'Yes',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      message: (
        <span>
          You’re <b>flexible with</b> how much of your <b>equity in cash</b> you
          receive <b>at closing</b>.
        </span>
      ),
      agentMessage: (
        <span>
          The owner is <b>flexible with</b> how much of their{' '}
          <b>equity in cash</b> they receive <b>at closing</b>.
        </span>
      ),
      points: [
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.GROUND_OR_MASTER_LEASE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.PARTNERSHIP,
          points: 1,
        },
      ],
    },
    {
      answer: 'Yes, but I want most of my equity in cash at closing',
      agentAnswer: `Yes, but the owner wants most of their equity in cash at closing`,
      message: (
        <span>
          You want <b>most</b> of your <b>equity in cash</b> at closing.
        </span>
      ),
      agentMessage: (
        <span>
          The owner wants <b>most</b> of their <b>equity in cash</b> at closing.
        </span>
      ),

      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
    {
      message: (
        <span>
          You want <b>all</b> of your <b>equity in cash</b> at closing.
        </span>
      ),
      agentMessage: (
        <span>
          The owner wants <b>all</b> of their <b>equity in cash</b> at closing.
        </span>
      ),
      answer: 'No, I want all of my equity at closing (100% cash out)',
      agentAnswer: `No, the owner wants all of their equity at closing (100% cash out)`,
      nextQuestion: QUESTION_IDS.DO_YOU_PLAN_ON_EXCHANGE,
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 5,
        },
      ],
    },
  ],
};

const DO_ANY_OF_THESE_APPLY = {
  id: QUESTION_IDS.DO_ANY_OF_THESE_APPLY,
  questionNumber: 3,
  question: 'Do any of these apply? I want to sell my property...',
  agentQuestion:
    'Do any of these apply? The owner wants to sell their property...',
  answers: [
    {
      answer: 'and business',
      message: (
        <span>
          You want to <b>sell</b> your <b>property and business</b>.{' '}
        </span>
      ),
      agentMessage: (
        <span>
          The owner wants to <b>sell</b> their <b>property and business</b>.
        </span>
      ),
      nextQuestion: QUESTION_IDS.OPEN_TO_BEING_PAID_OVERTIME,
      points: [
        {
          offerType: OFFER_TYPES.BUSINESS_BUILDING_SALE,
          points: 5,
        },
      ],
    },
    {
      answer: 'mid-way through major project',
      message: (
        <span>
          You want to <b>sell</b> <b>mid-project</b>.{' '}
        </span>
      ),
      agentMessage: (
        <span>
          The owner wants to <b>sell</b> <b>mid-project</b>.
        </span>
      ),
      nextQuestion: QUESTION_IDS.OPEN_TO_BEING_PAID_OVERTIME,
      points: [
        {
          offerType: OFFER_TYPES.BUILDER_PRESALE,
          points: 5,
        },
      ],
    },
    {
      answer: 'and lease it back',
      message: (
        <span>
          You want to <b>sell</b> <b>and lease-back</b>.{' '}
        </span>
      ),
      agentMessage: (
        <span>
          The owner wants to <b>sell</b> <b>and lease-back</b>.
        </span>
      ),
      nextQuestion: QUESTION_IDS.OPEN_TO_BEING_PAID_OVERTIME,
      points: [
        {
          offerType: OFFER_TYPES.SALE_LEASEBACK,
          points: 5,
        },
      ],
    },
    {
      answer: 'None of these',
      nextQuestion: QUESTION_IDS.OPEN_TO_BEING_PAID_OVERTIME,
      points: [],
    },
  ],
};

const WHICH_TRANSACTION_TYPE = {
  id: QUESTION_IDS.WHICH_TRANSACTION_TYPE,
  question: 'Which transaction type are you leaning towards?',
  agentQuestion: 'Which transaction type is the owner leaning towards?',
  questionNumber: 2,
  answers: [
    {
      answer: 'Sale',
      nextQuestion: QUESTION_IDS.DO_ANY_OF_THESE_APPLY,
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
    {
      answer: 'Lease',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [
        {
          offerType: OFFER_TYPES.GROUND_OR_MASTER_LEASE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
    {
      answer: 'Partnership to renovate or redevelop',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [
        {
          offerType: OFFER_TYPES.PARTNERSHIP,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
    {
      answer: 'Other',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [],
    },
    {
      answer: 'Not sure- exploring options',
      nextQuestion: QUESTION_IDS.WHATS_YOUR_PRIMARY_GOAL,
      points: [],
    },
  ],
};

const WHY_TRANSACT = {
  id: QUESTION_IDS.WHY_TRANSACT,
  question: 'Why are you considering a transaction?',
  agentQuestion: 'Why is the owner considering a transaction?',
  subTitle: 'Select all that apply',

  questionNumber: -1,
  answers: [
    {
      answer: 'Achieve money goals',
      nextQuestion: QUESTION_IDS.WHICH_TRANSACTION_TYPE,
      points: [],
    },
    {
      answer: 'Solve problem or puzzle',
      nextQuestion: QUESTION_IDS.WHICH_TRANSACTION_TYPE,
      points: [],
    },
    {
      answer: 'Simplify life',
      nextQuestion: QUESTION_IDS.WHICH_TRANSACTION_TYPE,
      points: [
        {
          offerType: OFFER_TYPES.EASY_SALE,
          points: 1,
        },
        {
          offerType: OFFER_TYPES.INSTALLMENT_SALE,
          points: 1,
        },
      ],
    },
    {
      answer: 'Other',
      nextQuestion: QUESTION_IDS.WHICH_TRANSACTION_TYPE,
      points: [],
    },
  ],
};

const QUESTIONS = [
  WHY_TRANSACT,
  WHICH_TRANSACTION_TYPE,
  DO_ANY_OF_THESE_APPLY,
  OPEN_TO_BEING_PAID_OVERTIME,
  DO_YOU_PLAN_ON_EXCHANGE,
  WHATS_YOUR_PRIMARY_GOAL,
  INVESTMENT_RISK_APPETITE,
  MAKE_YOUR_TRANSACTION_BETTER,
  FLOW_DONE,
];

export { ADDON_IDS, QUESTIONS, QUESTION_IDS, OFFER_TYPES };
