import React, { useEffect, useRef, useContext } from "react";
import { PROPERTY_RELATIONSHIP_ANSWERS } from "../GetFreeOffer.container";
import { FORM_URLS } from "../../../constants/pardot";
import Context from "../../../context/Context";
import emailjs from "@emailjs/browser";
import { EMAIL_JS } from "src/constants/emailJs";

const FORM_FIELD_NO_INPUT = "Not specified";

function GetFreeOfferForm({
  email = FORM_FIELD_NO_INPUT,
  phone = FORM_FIELD_NO_INPUT,
  propertyRelationship = FORM_FIELD_NO_INPUT,
  specificRelationship = FORM_FIELD_NO_INPUT,
  propertyCondition = FORM_FIELD_NO_INPUT,
  transactionDuration = FORM_FIELD_NO_INPUT,
  firstName = FORM_FIELD_NO_INPUT,
  lastName = FORM_FIELD_NO_INPUT,
  primaryOffer = FORM_FIELD_NO_INPUT,
  secondaryOffer = FORM_FIELD_NO_INPUT,
  addOn1 = FORM_FIELD_NO_INPUT,
  addOn2 = FORM_FIELD_NO_INPUT,
  addOn3 = FORM_FIELD_NO_INPUT,
  children,
}) {
  const {
    actions: { onOfferSubmissionComplete },
    state: { addressData },
  } = useContext(Context);

  let { street, city, state, zip } = addressData;

  if (!street) {
    street = FORM_FIELD_NO_INPUT;
  }
  if (!city) {
    city = FORM_FIELD_NO_INPUT;
  }
  if (!state) {
    state = FORM_FIELD_NO_INPUT;
  }
  if (!zip) {
    zip = FORM_FIELD_NO_INPUT;
  }

  const fields = [
    {
      name: "property-relationship",
      value:
        propertyRelationship === PROPERTY_RELATIONSHIP_ANSWERS.other
          ? specificRelationship
          : propertyRelationship,
    },
    {
      name: "Primary-Offer-Type",
      value: primaryOffer,
    },
    {
      name: "Secondary-Offer-Type",
      value: secondaryOffer,
    },
    {
      name: "Add-on-1",
      value: addOn1,
    },
    {
      name: "Add-on-2",
      value: addOn2,
    },
    {
      name: "Add-on-3",
      value: addOn3,
    },
    {
      name: "property-condition",
      value: propertyCondition,
    },
    {
      name: "transaction-duration",
      value: transactionDuration,
    },
    {
      name: "first-name",
      value: firstName,
    },
    {
      name: "last-name",
      value: lastName,
    },
    {
      name: "email",
      value: email,
    },
    {
      name: "phone",
      value: phone,
    },
    {
      name: "street-address",
      value: street,
    },
    {
      name: "city",
      value: city,
    },
    {
      name: "state",
      value: state,
    },
    {
      name: "zip",
      value: zip,
    },
  ];

  if (typeof window === "undefined") {
    return null;
  }

  const emailJsForm = useRef();

  const sendForm = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        EMAIL_JS.SERVICE_ID,
        EMAIL_JS.TEMPLATE_ID,
        emailJsForm.current,
        {
          publicKey: EMAIL_JS.PUBLIC_KEY,
        }
      )
      .then(
        () => {
          window.location.href = `${window.location.origin}/get-offer/submitted?pi_list_email=${email}&flowsuccess=true`;
        },
        (error) => {
          window.location.href = `${window.location.origin}/404`;
        }
      );
  };

  return (
    <form
      ref={emailJsForm}
      onSubmit={sendForm}
      // action="https://formspree.io/f/movqdnoz"
      // method="POST"
    >
      <input
        type="hidden"
        name="success_location"
        value={`${window.location.origin}/get-offer/submitted?pi_list_email=${email}&flowsuccess=true`}
      />
      {fields.map((field, index) => (
        <input
          key={index}
          type="hidden"
          name={field.name}
          value={field.value}
        />
      ))}
      {children}
    </form>
  );
}

export default GetFreeOfferForm;
