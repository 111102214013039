import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import store from 'store2';
import { useLocation } from '@reach/router';

import LOCAL_KEYS from 'src/constants/localStorage';

import Context from './Context';
import { PHONE } from '../constants/contact';
import { PROPERTY_RELATIONSHIP_ANSWERS } from '../components/GetFreeOffer/GetFreeOffer.container';
import QUOTE_FLOW_STEPS from '../constants/quoteFlowSteps';
import { SEARCH_PARAMS } from 'src/constants/searchParams';

class ContextProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    addressData: {
      address: '',
    },
    questionAnswers: {
      specificRelationship: '',
      propertyCondition: '',
      transactionDuration: '',
      propertyRelationship: '',
    },
    offerTypeId: '',
    formProgress: 1,
    didManuallyEnterAddress: false,
    comparedOfferTypes: [],
    contactNumber: PHONE,
    getAQuoteFlowData: {},
    getAQuoteActiveStep: QUOTE_FLOW_STEPS.INTRO,
    flowSource: '',
    flowType: '',
    isAddressSearchFocused: false,
    isContactModalOpen: false,
    isLoveYourOfferModalOpen: false,
    isTermsModalOpen: false,
    isContinueOfferBarOpen: false,
    isNavMenuOpen: false,
    isLocalDataLoaded: false,
    toastType: null,
    shouldDisplayFlexCheck: false,
  };

  getAnswers = () => {
    const answers = store(LOCAL_KEYS.FLOW_ADDRESS_QUESTIONS);

    const defaultAnswers = {
      specificRelationship: '',
      propertyCondition: '',
      transactionDuration: '',
      propertyRelationship: '',
    };

    return {
      ...defaultAnswers,
      ...answers,
    };
  };

  componentDidMount() {
    this.checkURLParams();
    const formProgress = store(LOCAL_KEYS.FLOW_FORM_PROGRESS);
    const localAddressData = store(LOCAL_KEYS.ADDRESS);
    const localFlowData = store(LOCAL_KEYS.FLOW_DATA);
    const localFlowStepData = store(LOCAL_KEYS.FLOW_STEP);
    const localFlowType = store(LOCAL_KEYS.FLOW_TYPE);
    const localFlowSource = store(LOCAL_KEYS.FLOW_SOURCE);
    const answers = store(LOCAL_KEYS.FLOW_ADDRESS_QUESTIONS);
    const offerTypeId = store(LOCAL_KEYS.OFFER_TYPE_ID);

    const defaultAnswers = {
      specificRelationship: '',
      propertyCondition: '',
      transactionDuration: '',
      propertyRelationship: '',
    };

    const localAddressManuallyEntered = store(
      LOCAL_KEYS.DID_MANUALLY_ENTER_ADDRESS
    );

    const searchParams = new URLSearchParams(window.location.search);

    const isContinueOffer = searchParams.get(SEARCH_PARAMS.CONTINUE_OFFER);

    const path = window.location.pathname;

    const pathParts = path.split('/');

    if (pathParts.includes('get-offer-continue')) {
      this.setState({
        offerTypeId: offerTypeId ?? '',
        formProgress: formProgress ?? 1,
        addressData: localAddressData,
        questionAnswers: {
          ...answers,
        },
      });

      return;
    }

    // if (Boolean(isContinueOffer)) {
    //   this.setState({
    //     offerTypeId: offerTypeId ?? "",
    //     formProgress: formProgress ?? 1,
    //     addressData: localAddressData,
    //     questionAnswers: {
    //       ...answers,
    //     },
    //   });

    //   return;
    // } else {
    // }
    // We don't want to show the banner if the user has completed the flow already.
    // if (localFlowDone) return;

    if (localAddressManuallyEntered) {
      this.setState({ didManuallyEnterAddress: true });
    }

    if (localAddressData && localAddressData.address) {
      this.handleChangeIsContinueOfferBarOpen(true);
    }

    if (localFlowData && Object.keys(localFlowData).length > 0) {
      this.handleSetGetAQuoteFlowData(localFlowData);
      this.handleChangeIsContinueOfferBarOpen(true);
    }

    if (localFlowType) {
      this.handleSetFlowType(localFlowType);
    }

    if (localFlowSource) {
      this.handleSetFlowSource(localFlowSource);
    }

    this.setState({
      isLocalDataLoaded: true,
    });

    setTimeout(() => {
      const callRail = document.createElement('script');
      callRail.src =
        'https://cdn.callrail.com/companies/848848106/56d827b62f61e6ca363d/12/swap.js';
      callRail.async = true;
      document.body.appendChild(callRail);

      if (window.CallTrk) {
        window.CallTrk.getSwapNumbers(['6193539425'], (result) => {
          const newNum = result['6193539425'];
          const formattedNum = `${newNum.substring(0, 3)}-${newNum.substring(
            3,
            6
          )}-${newNum.substring(6, 11)}`;
          this.setState({
            contactNumber: formattedNum,
          });
        });
      }
    }, 500);

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      /* eslint-disable */
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const el = document.querySelector(this.getAttribute('href'));
        const yOffset = -110;
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      });
      /* eslint-enable */
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      addressData,
      flowSource,
      flowType,
      getAQuoteActiveStep,
      getAQuoteFlowData,
      didManuallyEnterAddress,
      questionAnswers,
      formProgress,
      offerTypeId,
    } = this.state;

    if (questionAnswers !== prevState.questionAnswers) {
      store(LOCAL_KEYS.FLOW_ADDRESS_QUESTIONS, questionAnswers);
    }

    if (didManuallyEnterAddress !== prevState.didManuallyEnterAddress) {
      store(LOCAL_KEYS.DID_MANUALLY_ENTER_ADDRESS, didManuallyEnterAddress);
    }

    if (addressData.address !== prevState.addressData.address) {
      store(LOCAL_KEYS.ADDRESS, addressData);
    }

    if (formProgress !== prevState.formProgress) {
      store(LOCAL_KEYS.FLOW_FORM_PROGRESS, formProgress);
    }

    if (getAQuoteFlowData !== prevState.getAQuoteFlowData) {
      store(LOCAL_KEYS.FLOW_DATA, getAQuoteFlowData);
    }

    if (getAQuoteActiveStep !== prevState.getAQuoteActiveStep) {
      store(LOCAL_KEYS.FLOW_STEP, getAQuoteActiveStep);
    }

    if (flowType !== prevState.flowType) {
      store(LOCAL_KEYS.FLOW_TYPE, flowType);
    }

    if (flowSource !== prevState.flowSource) {
      store(LOCAL_KEYS.FLOW_SOURCE, flowSource);
    }

    if (offerTypeId !== prevState.offerTypeId) {
      store(LOCAL_KEYS.OFFER_TYPE_ID, offerTypeId);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Context.Provider
        value={{
          state: {
            ...this.state,
          },
          actions: this.getActions(),
        }}
      >
        {children}
      </Context.Provider>
    );
  }

  checkURLParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailSuccess = urlParams.get('emailsuccess');
    const formSuccess = urlParams.get('formsuccess');

    if (emailSuccess) {
      this.setState({
        toastType: 'email',
      });
    } else if (formSuccess) {
      this.setState({
        toastType: 'form',
      });
    }
  };

  handleChangeIsNavMenuOpen = (isNavMenuOpen) => {
    this.setState({
      isNavMenuOpen,
    });
  };

  handleSetAddressData = (data) => {
    this.setState({
      addressData: {
        isContinueOfferBarOpen: true,
        ...data,
      },
      questionAnswers: {
        specificRelationship: '',
        propertyCondition: '',
        transactionDuration: '',
        propertyRelationship: '',
      },
      formProgress: 1,
    });
  };

  handleSetGetAQuoteFlowData = (data) => {
    const { getAQuoteFlowData } = this.state;

    this.setState({
      getAQuoteFlowData: {
        ...getAQuoteFlowData,
        ...data,
      },
    });
  };

  handleOfferSubmissionComplete = () => {
    this.setState({
      addressData: {},
      formProgress: 1,
      questionAnswers: {
        specificRelationship: '',
        propertyCondition: '',
        transactionDuration: '',
        propertyRelationship: '',
      },
      isContinueOfferBarOpen: false,
      didManuallyEnterAddress: false,
    });

    store.remove(LOCAL_KEYS.ADDRESS);
  };

  handleSetGetAQuoteActiveStep = (step) => {
    this.setState({
      getAQuoteActiveStep: step,
    });
  };

  handleSetAddressSearchFocused = (isFocused) => {
    this.setState({
      isAddressSearchFocused: isFocused,
    });
  };

  handleChangeIsContactModalOpen = (isContactModalOpen) => {
    this.setState({
      isContactModalOpen,
    });
  };

  handleChangeIsLoveYourOfferModalOpen = (isLoveYourOfferModalOpen) => {
    this.setState({
      isLoveYourOfferModalOpen,
    });
  };

  handleChangeIsTermsModalOpen = (isTermsModalOpen) => {
    this.setState({
      isTermsModalOpen,
    });
  };

  handleChangeIsContinueOfferBarOpen = (isContinueOfferBarOpen) => {
    this.setState(
      {
        isContinueOfferBarOpen,
      },
      () => {
        // Reset the flow data is user closes the "continue" bar.
        if (!isContinueOfferBarOpen) {
          this.resetEverything();
        }
      }
    );
  };

  handleShowIntercom = () => {
    window.Intercom('show');
  };

  handleRemoveToast = () => {
    this.setState({
      toastType: null,
    });
  };

  handleSetFlowType = (flowType) => {
    this.setState({
      flowType,
    });
  };

  handleSetFlowSource = (flowSource) => {
    this.setState({
      flowSource,
    });
  };

  handleAddComparedOfferType = (offerType) => {
    const { comparedOfferTypes } = this.state;

    const listIndex = comparedOfferTypes.findIndex(
      (type) => type === offerType
    );
    const newComparedOfferTypes = [...comparedOfferTypes];

    if (listIndex > -1) {
      newComparedOfferTypes.splice(listIndex, 1);
    } else {
      newComparedOfferTypes.push(offerType);
    }

    this.setState({
      comparedOfferTypes: newComparedOfferTypes,
    });
  };

  handleClearAllComparedOfferTypes = () => {
    this.setState({
      comparedOfferTypes: [],
    });
  };

  isValidAddressData = () => {
    const { addressData } = this.state;

    if (!addressData.address) {
      return false;
    }
    if (!addressData.city) {
      return false;
    }
    if (!addressData.state) {
      return false;
    }
    if (!addressData.zip) {
      return false;
    }
    if (!addressData.lat) {
      return false;
    }

    if (!addressData.lng) {
      return false;
    }
    return true;
  };

  resetEverything = () => {
    this.setState({
      addressData: { address: '' },
      flowType: '',
      formProgress: 1,
      getAQuoteFlowData: {},
      getAQuoteActiveStep: '',
      questionAnswers: {
        specificRelationship: '',
        propertyCondition: '',
        transactionDuration: '',
        propertyRelationship: '',
      },
    });

    store.remove(LOCAL_KEYS.FLOW_DONE);
    store.remove(LOCAL_KEYS.FLOW_DATA);
    store.remove(LOCAL_KEYS.FLOW_STEP);
    store.remove(LOCAL_KEYS.FLOW_TYPE);
    store.remove(LOCAL_KEYS.FLOW_SOURCE);
    store.remove(LOCAL_KEYS.ADDRESS);
    store.remove(LOCAL_KEYS.FLOW_FORM_PROGRESS);
  };

  onUpdateQuestionAnswers = (answers) => {
    this.setState({
      questionAnswers: {
        ...this.state.questionAnswers,
        ...answers,
      },
    });
  };

  handleSetOfferType = (id) => {
    this.setState({
      offerTypeId: id,
    });
  };

  onManuallyEnteredAddress = () => {
    this.setState({
      didManuallyEnterAddress: true,
    });
  };

  onFormProgressChange = (value) => {
    this.setState({
      formProgress: value,
    });
  };

  resetOfferType = () => {
    store.remove(LOCAL_KEYS.OFFER_TYPE_ID);
  };

  resetData = () => {
    this.setState({
      getAQuoteFlowData: {},
      getAQuoteActiveStep: '',
    });

    store.remove(LOCAL_KEYS.FLOW_DATA);
    store.remove(LOCAL_KEYS.FLOW_STEP);
  };

  addEventToDataLayer = (eventName, properties) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: eventName,
      ...properties,
    });
  };

  setShouldDisplayFlexCheck = (value) => {
    this.setState({
      shouldDisplayFlexCheck: value,
    });
  };

  getActions = () => ({
    addEventToDataLayer: this.addEventToDataLayer,
    onAddComparedOfferType: this.handleAddComparedOfferType,
    onChangeIsContactModalOpen: this.handleChangeIsContactModalOpen,
    onChangeIsNavMenuOpen: this.handleChangeIsNavMenuOpen,
    onChangeIsLoveYourOfferModalOpen: this.handleChangeIsLoveYourOfferModalOpen,
    onChangeIsTermsModalOpen: this.handleChangeIsTermsModalOpen,
    onChangeIsContinueOfferBarOpen: this.handleChangeIsContinueOfferBarOpen,
    onClearAllComparedOfferTypes: this.handleClearAllComparedOfferTypes,
    onSetAddressData: this.handleSetAddressData,
    onSetOfferTypeId: this.handleSetOfferType,
    onSetAddressSearchFocused: this.handleSetAddressSearchFocused,
    onSetFlowType: this.handleSetFlowType,
    onSetGetAQuoteActiveStep: this.handleSetGetAQuoteActiveStep,
    onSetGetAQuoteFlowData: this.handleSetGetAQuoteFlowData,
    onSetFlowSource: this.handleSetFlowSource,
    isValidAddressData: this.isValidAddressData,
    onShowIntercom: this.handleShowIntercom,
    onRemoveToast: this.handleRemoveToast,
    resetEverything: this.resetEverything,
    resetData: this.resetData,
    onDidManuallyEnterAddress: this.onManuallyEnteredAddress,
    onOfferSubmissionComplete: this.handleOfferSubmissionComplete,
    onUpdateQuestionAnswers: this.onUpdateQuestionAnswers,
    onFormProgressChange: this.onFormProgressChange,
    resetOfferType: this.resetOfferType,
    setShouldDisplayFlexCheck: this.setShouldDisplayFlexCheck,
  });
}

// Wrap component in order to use hooks with context
const WrappedContextProvider = (props) => <ContextProvider {...props} />;

export default WrappedContextProvider;
