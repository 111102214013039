import React, { useEffect } from 'react';
import IconClose from 'src/components/icons/Close/IconClose';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  };
`;

interface StyledProps {
  maxWidth: string;
  isFullHeight: boolean;
}

const ModalWrapper = styled.div<StyledProps>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: -webkit-fill-available;
  width: 100%;
  z-index: 9999999999999;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.2s ease-in-out;
  .modal-content {
    position: relative;
    max-width: ${({ maxWidth }) => maxWidth};
    background: white;
    width: 100%;
    overflow-y: auto;
    height: ${({ isFullHeight }) => (isFullHeight ? '100%' : 'auto')};
    padding: 32px 24px;
    @media (min-width: 600px) {
      height: ${({ isFullHeight }) => (isFullHeight ? '100%' : 'auto')};
      /*       box-shadow: ${({ theme }) => theme.layout.boxShadow};
 */
      border-radius: ${({ theme }) => theme.layout.borderRadius};
    }
  }
`;

interface Props {
  isFullHeight?: boolean;
  children: React.ReactNode;
  maxWidth?: string;
  onOutsideClick: () => void;
}

function Modal({
  isFullHeight = false,
  children,
  maxWidth = '600px',
  onOutsideClick,
}: Props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <ModalWrapper
      isFullHeight={isFullHeight}
      onClick={onOutsideClick}
      maxWidth={maxWidth}
    >
      <div
        onClick={(event) => event.stopPropagation()}
        className="modal-content"
      >
        {children}
      </div>
    </ModalWrapper>
  );
}

interface CloseButtonProps {
  onCloseClick: () => void;
}

const StyledModalCloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  svg {
    height: 16px !important;
    width: 16px !important;
    fill: ${({ theme }) => theme.color.interface500};
    margin: 0px;
    :hover {
      fill: ${({ theme }) => theme.color.interface800};
    }
  }
`;

const ModalCloseButton = ({ onCloseClick }: CloseButtonProps) => (
  <StyledModalCloseButton
    onClick={onCloseClick}
    className="modal__button-close"
  >
    <IconClose />
  </StyledModalCloseButton>
);

Modal.CloseButton = ModalCloseButton;
export default Modal;
