import styled from 'styled-components';

import { media } from 'src/utils/mixins';

import Lock from 'src/components/icons/Lock';

export const FormWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.layout.sidePadding.xsm} 160px;

  ${media.small`
    padding: 0 ${({ theme }) => theme.layout.sidePadding.sm} 160px;
  `}

  ${media.medium`
    padding: 0 ${({ theme }) => theme.layout.sidePadding.md} 160px;
  `}

  ${media.large`
    padding: 0 ${({ theme }) => theme.layout.sidePadding.lg} 160px;
  `}

  ${media.xLarge`
    padding: 0 ${({ theme }) => theme.layout.sidePadding.xlg} 160px;
  `}
`;

export const PrivacyPolicy = styled.div`
  ${({ theme }) => theme.text.graphikRegular};

  color: ${({ theme }) => theme.color.interface700};
  font-size: 10px;
  text-align: center;

  > a {
    color: ${({ theme }) => theme.color.primary600};
    text-decoration: none;
    transition: color 200ms ease;

    &:hover {
      color: ${({ theme }) => theme.color.primary500};
    }
  }
`;

export const LockIcon = styled(Lock)`
  fill: ${({ theme }) => theme.color.interface800};
  height: 10px;
  margin-right: 5px;
  width: 8px;
`;
