import styled from 'styled-components';


type TitleProps = {
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl';
  isSerif?: boolean;

}

export const Title = styled.div<TitleProps>`
  color: ${({ theme }) => theme.color.interface800};
  font-size: ${({ size = 'medium' }) => {

    if (size === 'xs') {
      return "18px"
    }

    if (size === 'small') {
      return '20px';
    }

    if (size === 'medium') {
      return "32px"
    }

    if (size === 'large') {
      return '32px';
    }

    if (size === 'xl') {
      return '40px';
    }
    return '24px';
  }};
  ${({ theme, isSerif = true }) =>
    isSerif ? theme.text.centurySchoolbook : theme.text.graphikMedium};
  font-weight: ${({ isSerif }) => (isSerif ? 400 : 500)};

  @media (min-width: 640px) {


    font-size: ${({ size = 'medium' }) => {

    if (size === 'xs') {
      return "18px"
    }

    if (size === 'small') {
      return '  ';
    }

    if (size === 'medium') {
      return "40px"
    }

    if (size === 'large') {
      return '56px';
    }
    if (size === 'xl') {
      return '64px';
    }

    return '48px';
  }};
  }
`;
